import React from 'react'
import styled, { css } from 'styled-components'
import Text from './Text'
import { up } from '../lib/styles'

const TOOLTIP_WIDTH = 350
const MIN_DISTANCE_LEFT = 20
const MIN_DISTANCE_RIGHT = 40
const BREAKPOINT = TOOLTIP_WIDTH + MIN_DISTANCE_LEFT + MIN_DISTANCE_RIGHT + 1

const Content = styled.div`
  ${({ theme: { colors } }) => css`
    background: ${colors.backgroundBlue};
    background: white;
    border-radius: 6px;
    box-shadow: 0 10px 60px -10px rgba(4, 62, 134, 0.2);
    border: solid 1px ${colors.darkBlue};
    padding: 10px 20px;
  `}
`

const triangle = (color, offset) => css`
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(calc(-50% - ${offset}px), 0);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid ${color};
`

const TooltipWrapper = styled.div`
  ${({ theme: { colors }, isPopup = false, offset, forcedOverflow }) => css`
    z-index: 500;
    position: absolute;
    left: 50%;
    /* left: 20px; */
    visibility: hidden;
    padding: 10px;
    text-decoration: none;
    max-width: ${TOOLTIP_WIDTH}px;
    width: 100vw;
    top: 0;
    transform: translate(calc(-50% + ${offset}px), -100%);

    ${forcedOverflow
      && css`
      left: unset;
      top: unset;
      transform: translate(calc(-50% + ${offset}px + 13px), calc(-100% - 35px));
      `}

    ${isPopup
      && css`
        width: 100vw;
        max-width: ${TOOLTIP_WIDTH}px;
        top: unset;
        transform: translate(-50%, -120%);
        left: 50%;
      `}

    &:hover {
      visibility: visible;
    }

    ${up(BREAKPOINT)} {
      &::before {
        bottom: 0;
        opacity: 0.8;
        ${triangle(colors.darkBlue, offset)}
      }
      &::after {
        bottom: 2px;
        ${triangle('white', offset)}
      }
    }
  `}
`

const Wrapper = styled.div`
  ${({ noUnderline }) => css`
    position: relative;
    display: inline;
    cursor: pointer;
    border-bottom: 1px dashed #324155;
    ${noUnderline
      && css`
        border-bottom: none;
      `}
    &:hover + ${TooltipWrapper} {
      visibility: visible;
    }
    &:hover ${TooltipWrapper} {
      visibility: visible;
    }
  `}
`

class Tooltip extends React.Component {
  constructor(props) {
    super(props)
    this.state = { width: 0, offset: 0 }
    this.tooltipRef = React.createRef()
  }

  componentDidMount() {
    setTimeout(() => this.updateWindowDimensions(), 1)
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    const { offset } = this.state
    const width = window.innerWidth

    const rect = this.tooltipRef
      && this.tooltipRef.current
      && this.tooltipRef.current.getBoundingClientRect()
    if (!rect) return

    // const elementWidth = this.tooltipRef.current.offsetWidth

    const fromLeft = rect.left - offset
    const offsetLeft = fromLeft < MIN_DISTANCE_LEFT ? -(fromLeft - MIN_DISTANCE_LEFT) : 0

    const fromRight = width - rect.right + offset
    const offsetRight = fromRight < MIN_DISTANCE_RIGHT ? -(fromRight - MIN_DISTANCE_RIGHT) : 0

    // console.log('*********************************')
    // console.log('rect.left:', rect.left, 'rect.right:', rect.right, 'width:', width)
    // console.log('offset:', offset, 'fromRight:', fromRight, 'fromLeft:', fromLeft)
    // console.log('offsetLeft:', offsetLeft, 'offsetRight:', offsetRight)

    this.setState({
      width,
      offset: offsetLeft - offsetRight,
    })
  }

  render() {
    const { children, content, noUnderline, forcedOverflow, mobileContentStyle } = this.props
    const { width, offsetLeft, offsetRight, offset } = this.state
    const showAsPopup = width ? width < BREAKPOINT : false

    const TooltipComponent = props => (
      <TooltipWrapper {...props} ref={this.tooltipRef}>
        <Content>
          <Text.DataDescription
            color="darkBlue"
            fontWeight="700"
            lineHeight="24px"
          >
            {content}
          </Text.DataDescription>
        </Content>
      </TooltipWrapper>
    )

    return (
      <>
        <Wrapper noUnderline={noUnderline}>
          {children}
          {!showAsPopup && !forcedOverflow && (
            <TooltipComponent
              offsetLeft={offsetLeft}
              offsetRight={offsetRight}
              offset={offset}
            />
          )}
        </Wrapper>
        {!showAsPopup && forcedOverflow && (
        <TooltipComponent
          offsetLeft={offsetLeft}
          offsetRight={offsetRight}
          offset={offset}
          forcedOverflow
        />
        )}
        {showAsPopup && <TooltipComponent isPopup style={mobileContentStyle} />}
      </>
    )
  }
}

export default Tooltip
