import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { sortFaqs, splitFaqsToRows, extractKeystoneData } from '../lib/helpers'
import Col from './Col'
import Row from './Row'
import Gap from './Gap'
import Faq from './Faq'
import Link from './Link'
import Button from './Button'
import Title from './Title'
import Container from './Container'

const data = [
  {
    title: 'Vzhled',
    content:
      'Kvalitní voda je obvykle bezbarvá a bez zákalu (tedy povlaku či pěny). Výjimečně se může objevit zabarvení; to je zpravidla nezávadné, ale je důležité zjistit jeho příčinu.',
  },
  {
    title: 'Pach',
    content:
      'Aroma vody by mělo být „přijatelné pro odběratele“. Nezávadná voda je často bez zápachu, případně její vůně připomíná chlor.',
  },
  {
    title: 'Teplota',
    content:
      'Optimální teplota (neohřáté) vody je 8–12 °C; nad 15 °C již ztrácí své osvěžující schopnosti, v teplotě nad 20 °C se zase dobře daří mikroorganismům.',
  },
  {
    title: 'pH',
    content:
      'Čistá voda mívá pH v rozmezí 6–9,5; typicky se uvádí hodnota 7,0. Voda s příliš nízkým pH je agresivní, s příliš vysokým se snižuje účinnost její dezinfekce.',
  },
  {
    title: 'Železo',
    content:
      'Tento prvek je ve vodě běžný, zejména pak v kyselejších a podzemních vodách, případně při korodujících trubkách. Překročení mezní hodnoty železa nepředstavuje zdravotní riziko; může ale zhoršit chuť vody nebo zabarvit prádlo při praní.',
  },
  {
    title: 'Dusičnany',
    content:
      'Do vody se dostávají ze zemědělských hnojiv nebo odpadních vod jako závěrečný stupeň rozkladu dusíkatých látek. Překročení jejich mezní hodnoty je nebezpečné pro kojence do 4 měsíců věku.',
  },
  {
    title: 'Množství chloru',
    content:
      'Chlor (případně chlornan sodný) se do vody přidává k její dezinfekci a potlačení množení bakterií. V některých místech v síti může mít voda silnější aroma či chuť. Pokud si na nádech chloru zrovna nepotrpíte, nechte vodu odstát v pokojové teplotě, převařte ji nebo do ní přidejte několik kapek citronu.',
  },
  {
    title: 'Tvrdost vody',
    content:
      'Označení tvrdá a měkká voda odráží množství rozpuštěných minerálů. I když obsah vápníku nebo hořčíku nijak neohrožuje lidské zdraví (naopak na něj mívá pozitivní vliv), ovlivňuje její chuť, účinnost pracích prostředků nebo tvorbu vodního kamene.',
  },
]

const QualityMeasureSection = props => (
  <Col width="100%" alignItems="center">
    {splitFaqsToRows(data).map((row, key) => (
      <Row
        width="100%"
        key={key}
        justifyContent="center"
        alignItems="flex-start"
        mobileAlignItems="stretch"
        responsive
        bp="mobile"
      >
        {row.map(({ title, content }, key) => (
          <Fragment key={key}>
            <Faq title={title} content={content} />
            {row.length === 1 && <Faq.Dummy />}
          </Fragment>
        ))}
      </Row>
    ))}
  </Col>
)

export default QualityMeasureSection
