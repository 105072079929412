// průměrná spotřeba vody na osobu na den v metrech krychlových
export const averageAmount = 90.6 / 1000

// ceny za metr krychlový (1000l)

const createData = (...args) => {
  const data = []
  args.forEach(dataArray => {
    const x = {
      year: dataArray[0],
      invoicedPrice: dataArray[1],
      dischargedPrice: dataArray[2],
      sumPrice: dataArray[1] + dataArray[2],
      dummy: 0, // workaround for graph bar stack label
    }
    data.push(x)
  })
  return data
}

export const invoicedAndDischargedVAT = {
  2019: 15,
}


export const countryData = createData(
  // [2013, 33.7, 29.2],
  [2014, 34.8, 29.8],
  [2015, 35.6, 30.7],
  [2016, 36.7, 32.1],
  [2017, 37.2, 32.8],
  [2018, 38.1, 33.4],
  [2019, 39.3, 34.7],
)

export const regions = [
  // 2018
  {
    label: 'Hlavní město Praha',
    data: createData(
      // [2013, 36.3, 29.0],
      [2014, 38.3, 27.8],
      [2015, 38.6, 28.6],
      [2016, 40.1, 32.3],
      [2017, 40.6, 33.8],
      [2018, 42.0, 34.0],
      [2019, 42.7, 35.3],
    ),
  },
  {
    label: 'Středočeský kraj',
    data: createData(
      // [2013, 36.7, 26.8],
      [2014, 37.5, 28.6],
      [2015, 38.4, 30.2],
      [2016, 39.0, 31.3],
      [2017, 40.2, 32.5],
      [2018, 40.9, 33.1],
      [2019, 41.9, 34.2],
    ),
  },
  {
    label: 'Jihočeský kraj',
    data: createData(
      // [2013, 34.8, 27.3],
      [2014, 35.3, 27.6],
      [2015, 35.8, 28.1],
      [2016, 35.4, 28.6],
      [2017, 36.3, 28.7],
      [2018, 37.0, 29.4],
      [2019, 37.6, 29.9],
    ),
  },
  {
    label: 'Plzeňský kraj',
    data: createData(
      // [2013, 31.8, 25.3],
      [2014, 33.3, 25.0],
      [2015, 33.2, 24.6],
      [2016, 37.5, 26.7],
      [2017, 38.1, 27.0],
      [2018, 39.2, 27.8],
      [2019, 40.8, 30.5],
    ),
  },
  {
    label: 'Karlovarský kraj',
    data: createData(
      // [2013, 36.2, 30.6],
      [2014, 36.4, 31.7],
      [2015, 36.1, 31.9],
      [2016, 36.6, 33.8],
      [2017, 36.9, 34.3],
      [2018, 38.2, 35.6],
      [2019, 40.1, 36.8],
    ),
  },
  {
    label: 'Ústecký kraj',
    data: createData(
      // [2013, 39.3, 37.1],
      [2014, 40.9, 38.4],
      [2015, 42.5, 39.9],
      [2016, 43.0, 41.4],
      [2017, 43.4, 41.4],
      [2018, 43.4, 41.7],
      [2019, 44.2, 41.8],
    ),
  },
  {
    label: 'Liberecký kraj',
    data: createData(
      // [2013, 36.9, 37.5],
      [2014, 38.5, 39.0],
      [2015, 39.8, 40.4],
      [2016, 42.2, 41.9],
      [2017, 42.7, 42.2],
      [2018, 44.2, 42.5],
      [2019, 44.8, 42.9],
    ),
  },
  {
    label: 'Královéhradecký kraj',
    data: createData(
      // [2013, 31.3, 31.2],
      [2014, 31.9, 31.7],
      [2015, 32.6, 32.6],
      [2016, 34.2, 33.1],
      [2017, 34.6, 33.2],
      [2018, 34.9, 34.2],
      [2019, 36.1, 35.6],
    ),
  },
  {
    label: 'Pardubický kraj',
    data: createData(
      // [2013, 30.0, 32.9],
      [2014, 30.8, 33.5],
      [2015, 31.0, 34.1],
      [2016, 31.9, 34.7],
      [2017, 32.9, 35.1],
      [2018, 34.1, 36.2],
      [2019, 35.9, 37.3],
    ),
  },
  {
    label: 'Kraj Vysočina',
    data: createData(
      // [2013, 33.2, 23.7],
      [2014, 33.8, 24.7],
      [2015, 34.7, 25.6],
      [2016, 35.4, 26.3],
      [2017, 36.3, 27.6],
      [2018, 37.1, 28.3],
      [2019, 38.5, 28.8],
    ),
  },
  {
    label: 'Jihomoravský kraj',
    data: createData(
      // [2013, 29.4, 30.2],
      [2014, 30.8, 31.4],
      [2015, 32.5, 32.4],
      [2016, 33.5, 33.2],
      [2017, 33.6, 33.5],
      [2018, 34.5, 34.5],
      [2019, 36.1, 36.2],
    ),
  },
  {
    label: 'Olomoucký kraj',
    data: createData(
      // [2013, 31.3, 27.7],
      [2014, 30.9, 29.0],
      [2015, 31.8, 29.3],
      [2016, 32.1, 31.0],
      [2017, 32.5, 31.3],
      [2018, 33.1, 32.1],
      [2019, 24.1, 33.9],
    ),
  },
  {
    label: 'Zlínský kraj',
    data: createData(
      // [2013, 33.5, 28.4],
      [2014, 34.1, 29.1],
      [2015, 34.7, 29.1],
      [2016, 35.4, 29.8],
      [2017, 35.5, 30.1],
      [2018, 35.8, 30.8],
      [2019, 37.2, 32.0],
    ),
  },
  {
    label: 'Moravskoslezský kraj',
    data: createData(
      // [2013, 30.4, 28.0],
      [2014, 31.5, 29.1],
      [2015, 32.4, 30.1],
      [2016, 33.3, 30.5],
      [2017, 33.7, 31.3],
      [2018, 34.4, 31.5],
      [2019, 36.2, 32.9],
    ),
  },
]
