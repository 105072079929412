import React from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import Col from './Col'
import Title from './Title'
import Box from './Box'
import Button from './Button'

const Article = styled(Box).attrs(() => ({ isArticle: true }))`
  position: relative;
  flex-basis: 270px;
  margin: auto;
`

const StyledImage = styled(Img)`
  width: 100%;
  height: 100%;
  border-radius: 6px;
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 6px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 0%,
      #ffffff 100%
    );
    border-radius: 6px;
    z-index: 10;
  }
`

const ArticleThumbnail = ({ image, title, withButton }) => (
  <Article>
    {image && (
      <ImageWrapper>
        <StyledImage fluid={image.image.fluid} />
      </ImageWrapper>
    )}
    <Col height="100%" grow={1} style={{ zIndex: 100 }}>
      <Col width="100%" grow={1} alignItems="center" justifyContent="center">
        <Title.Small>{title}</Title.Small>
      </Col>

      {withButton && (
        <Col width="100%" alignItems="center" justifyContent="center">
          <Button.Simple small>PŘEČÍST ČLÁNEK</Button.Simple>
        </Col>
      )}
    </Col>
  </Article>
)

export default ArticleThumbnail
