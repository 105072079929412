import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import moment from 'moment'
import Row from './Row'
import Container from './Container'
import Col from './Col'
import Box from './Box'
import Title from './Title'
import Gap from './Gap'
import Button from './Button'
import Link from './Link'
import ArticleThumbnail from './ArticleThumbnail'
import { extractKeystoneData } from '../lib/helpers'
import { up } from '../lib/styles'
import TapsBg from './TapsBg'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 270px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  width: 100%;

  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(0, 270px));

  ${up('mobile')} {
    /* grid-template-columns: repeat(2, 270px); */
  }
  ${up('tablet')} {
    /* grid-template-columns: repeat(3, 270px); */
    /* grid-template-columns: repeat(auto-fill, minmax(0, 270px)); */
  }
  ${up('desktop')} {
    /* grid-template-columns: repeat(4, 270px); */
    /* grid-template-columns: repeat(auto-fill, minmax(0, 270px)); */
  }
`

const ArticleSection = ({
  page,
  data,
  isArticle,
  categories,
  currentArticleId,
  pathname,
}) => {
  // smazat show on page kategorie

  const { allArticle } = extractKeystoneData(data)
  if (!allArticle) return null

  let filteredArticles = []
  if (page) {
    filteredArticles = allArticle.filter(({ categories }) =>
      categories.some(({ showOnPage }) => showOnPage === page))
  }

  if (isArticle) {
    const realCategories = categories.filter(({ isFilter }) => isFilter)
    filteredArticles = allArticle.filter(
      ({ _id, categories }) =>
        _id !== currentArticleId
        && categories.some(category =>
          realCategories.some(({ _id }) => category._id === _id)),
    )
  }

  if (pathname) {
    const simplePathname = pathname.split('/').join('')
    filteredArticles = filteredArticles.filter(({ slug }) => slug !== simplePathname)
  }

  if (!filteredArticles || filteredArticles.length === 0) return null

  filteredArticles = filteredArticles.slice(0, 4)
  filteredArticles.sort((a, b) => {
    if (!a.dateCreated) return 1
    if (!b.dateCreated) return -1
    const aMoment = moment(a.dateCreated)
    const bMoment = moment(b.dateCreated)
    return aMoment.isAfter(bMoment) ? -1 : 1
  })

  return (
    <Col width="100%" alignItems="center" position="relative">
      <Container>
        <Col width="100%" alignItems="center" position="relative">
          <Title textAlign="center">
            {isArticle ? 'Čtěte dále' : 'Články'}
          </Title>
          <Gap gap="40px" />
          <Grid>
            {filteredArticles.map(({ id, title, slug, image }) => (
              <Link to={`/${slug}`} key={id}>
                <ArticleThumbnail title={title} image={image} />
              </Link>
            ))}
          </Grid>
          <Gap gap="55px" />
          <Link to={Link.ARTICLES}>
            <Button.Simple>UKÁZAT DALŠÍ ČLÁNKY</Button.Simple>
          </Link>
        </Col>
      </Container>
      <TapsBg
        transparent
        right="0"
        top="70px"
        topMobile="70px"
        xCount={4}
        yCount={2}
        xCountMobile={4}
        yCountMobile={3}
        zIndex={-9}
      />
      <Gap gap="140px" mobileGap="80px" bp="tablet" />
    </Col>
  )
}

const WithQuery = props => (
  <StaticQuery
    query={query}
    render={queryResult => <ArticleSection {...props} data={queryResult} />}
  />
)

export default WithQuery

const query = graphql`
  query {
    allArticle(
      sort: { order: ASC, fields: sortOrder }
      filter: { isPublished: { eq: true } }
    ) {
      nodes {
        ...ArticleFragment
      }
    }
  }
`
