import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled, { css } from 'styled-components'
import { Text, Gap, Title, Col, Button, Row, Container } from '..'
import questions from './config'
import { up } from '../../lib/styles'

import chatIconSrc from '../../data/images/chat-icon.svg'
import checkSrc from '../../data/images/icons/check.svg'

const QUESTION_POINTS = 100 / questions.length

const MessageBox = styled.div`
  ${({ isUser, theme: { colors } }) => css`
    box-shadow: 0 10px 20px rgba(4, 62, 134, 0.2);
    border-radius: 20px 20px 20px 4px;
    background-color: #ffffff;
    padding: 20px 30px;
    ${isUser
      && css`
        align-self: flex-end;
        background: ${colors.backgroundBlue};
      `}
  `}
`

const AnswerButton = styled(Button.Transparent).attrs(() => ({
  padding: '10px',
  mobilePadding: '10px',
  minHeight: '50px',
}))`
  ${({ isSelected }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: ${isSelected ? 'hidden' : 'visible'};
    letter-spacing: normal;
    ${up('mobile')} {
      letter-spacing: 2px;
    }
  `}
`

const ScrollAbleWrapper = styled(Col)`
  ${({ theme: { colors } }) => css`
    min-height: 450px;
    max-height: 600px;
    overflow: auto;
    padding: 25px 20px;

    transition: 1s;

    & > :first-child {
      margin-top: auto !important;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: ${colors.backgroundBlue};
      background: #cbced04d;
      border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #0099fc4d;
      border-radius: 6px;
    }
  `}
`

const Time = styled.div`
  ${({ theme: { fonts }, right }) => css`
    opacity: 0.5;
    color: #0588dc;
    font-family: ${fonts.venti};
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 4px;
    align-self: ${right && 'flex-end'};
  `}
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  ${up('tablet')} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const Wrapper = styled(Row).attrs(() => ({
  width: '100%',
  justifyContent: 'center',
}))`
  ${({ isRunning }) => css`
    min-height: ${isRunning ? '800px' : '690px'};
    /* min-height: 800px; */
  `}
`

const ChatBotIconWrapper = styled.div`
  ${({ theme, isVisible }) => css`
    visibility: ${isVisible ? 'visible' : 'hidden'};
    width: 40px;
    img {
      max-width: 100%;
    }

    ${up('mobile')} {
      width: 60px;
    }
    ${up('tablet')} {
      width: 80px;
    }
  `}
`

const wait = (time = 2) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, time * 1000)
  })

const shouldShowChatIcon = ({ messages, key }) => {
  const currentMessage = messages[key]
  if (currentMessage.isUser) return false
  // if is last item
  if (key + 1 === messages.length) return true
  const nextMessage = messages[key + 1]
  if (!nextMessage.isUser) return false
  return true
}

const INITIAL_STATE = {
  activeQuestionIndex: -1,
  isAnswering: false,
  isRunning: false,
  isFinished: false,
  score: 0,
  // timerStartTimestamp: null,
  messages: [
    {
      text: 'Vítáme vás. 👋 Chcete si otestovat své znalosti?',
    },
    {
      text:
        'Připravili jsme si pro vás krátký kvíz o vodě, který obsahuje 10 otázek. Na odpověď budete mít vždy 30 sekund. Jdete do toho? 😊',
    },
  ],
}

class ChatBot extends Component {
  constructor(props) {
    super(props)
    this.wrapperRef = React.createRef()
    this.state = {
      ...INITIAL_STATE,
    }
  }

  componentWillUnmount() {
    this.clearTimer()
  }

  nextQuestion = async () => {
    this.clearTimer()
    const { activeQuestionIndex } = this.state
    const nextQuestionIndex = activeQuestionIndex + 1

    if (nextQuestionIndex === questions.length) {
      this.finish()
      return
    }
    await this.addMessage({ text: questions[nextQuestionIndex].preText })
    await this.addMessage({ text: questions[nextQuestionIndex].text })
    this.setState({
      activeQuestionIndex: nextQuestionIndex,
      isAnswering: true,
      attempts: 0,
      selectedAnswers: [],
    })
    this.startTimer()
  }

  startTimer = async (shouldContinue) => {
    const { activeQuestionIndex } = this.state
    const question = questions[activeQuestionIndex]

    // const timestamp = Date.now()
    // this.setState({ timerStartTimestamp: timestamp })

    // const timeoutTimeFirst = 20 * 1000
    // const timeoutTimeSecond = 10 * 1000

    // if (shouldContinue) {
    //   const remainingTime = timerStartTimestamp - timestamp
    //   console.log('remainingTime', remainingTime)

    // }

    this.timeoutId = setTimeout(() => {
      // 20 s limit
      this.addMessage('Tik tak, tik tak… čas běží. Jaká je vaše odpověď? 😊', 0)
      this.timeoutId = setTimeout(async () => {
        this.setState({ isAnswering: false })
        await this.addMessage(
          'Je nám líto, ale váš čas vypršel. Nezoufejte, správnou odpověď vám samozřejmě prozradíme i tak.',
        )
        // vypršení limitu
        await this.addMessage(question.finalInfo)
        return this.nextQuestion()
      }, 10 * 1000)
    }, 20 * 1000)
  }

  clearTimer = () => {
    if (this.timeoutId) clearTimeout(this.timeoutId)
  }

  answer = async ({ text, isRight, wrongAnswerMessage }, key, isSort) => {
    const { selectedAnswers, activeQuestionIndex, attempts, score } = this.state
    // todo timer je potřeba clearovat na začátku odpovědi, protože jsou tu awaity
    const question = questions[activeQuestionIndex]

    // ignore fast clicks on same button
    if (selectedAnswers.includes(key)) return false

    this.clearTimer()

    const newSelectedAnswers = [...selectedAnswers, key]
    this.setState({ selectedAnswers: newSelectedAnswers })

    if (isSort) {
      this.addMessage({ text, isUser: true }, 1)
      if (newSelectedAnswers.length !== question.rightAnswer.length) {
        return this.startTimer(true)
      }
      this.setState({ isAnswering: false })
      isRight = newSelectedAnswers.every(
        (answerIndex, key) => question.rightAnswer[key] === answerIndex,
      )
      await wait(1)
    } else {
      this.setState({ isAnswering: false })
      await this.addMessage({ text, isUser: true }, 1)
    }

    const newAttempts = attempts + 1
    if (isRight) {
      const newScore = score + QUESTION_POINTS / newAttempts
      this.setState({ score: newScore })
      await this.addMessage(question.rightAnswerMessage, 1)
      await this.addMessage(question.finalInfo, 4)
      return this.nextQuestion()
    }

    if (attempts === 0 && wrongAnswerMessage) {
      await this.addMessage(wrongAnswerMessage)
    } else {
      await this.addMessage(question.wrongAnswerMessages[attempts])
    }

    if (newAttempts === question.attempts) {
      await this.addMessage(question.finalInfo)
      return this.nextQuestion()
    }

    this.setState({ isAnswering: true })
    this.startTimer(true)
    return this.setState({ attempts: newAttempts })
  }

  addMessage = async (newMessage, time = 2) => {
    if (newMessage.time) time = newMessage.time
    const transformedMessage = typeof newMessage === 'string' ? { text: newMessage } : newMessage
    this.scrollToBottom()
    this.setState(({ messages }) => ({
      messages: [...messages, transformedMessage],
    }))
    return wait(time)
  }

  scrollToBottom = () => {
    if (this.wrapperRef && this.wrapperRef.current) {
      const wrapperRef = this.wrapperRef.current
      setTimeout(() => {
        wrapperRef.scrollTo({
          top: wrapperRef.scrollHeight,
          behavior: 'smooth',
        })
      }, 1)
    }
  }

  start = async () => {
    this.setState({ isRunning: true, isFinished: false })
    await this.addMessage({ text: 'Jdu do toho!', isUser: true }, 1)
    this.nextQuestion()
  }

  restart = () => {
    this.setState({
      ...INITIAL_STATE,
    })
    this.start()
  }

  finish = async () => {
    const { score } = this.state
    this.clearTimer()
    await this.addMessage(
      'Hurá, jste v cíli! Věříme, že jste se dozvěděli pár zajímavých informací o vodě, její ceně nebo spotřebě nejen u nás, ale i v Evropě.',
    )
    await this.addMessage(`Vaše úspěšnost je <b>${score} %</b>`)
    const quizLink = `${window.location.href}#kviz`
    await this.addMessage(
      // 'Nebuďte tajnůstkáři a podělte se i se svými přáteli. Jistě si též rádi otestují své znalosti a dozví se něco nového.',
      `Nebuďte tajnůstkáři a podělte se i se svými přáteli. Jistě si též rádi otestují své znalosti a dozví se něco nového.<br/> Odkaz na kvíz: <a href="${quizLink}">${quizLink}</a>`,
    )
    this.setState({ isFinished: true })
  }

  render() {
    const {
      activeQuestionIndex,
      messages,
      isAnswering,
      selectedAnswers,
      isRunning,
      isFinished,
    } = this.state
    const question = isRunning && questions[activeQuestionIndex]
    const showAnswersButtons = isAnswering && question

    return (
      <Wrapper isRunning={isRunning}>
        <Col maxWidth="740px" padding="0 0 50px 0">
          {/* <Gap gap="40px" /> */}
          <ScrollAbleWrapper ref={this.wrapperRef}>
            {messages.map(({ text, isUser }, key) => (
              <Fragment key={key}>
                <Gap gap="10px" />
                <Row
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  width="100%"
                >
                  <Col>
                    <ChatBotIconWrapper
                      isVisible={shouldShowChatIcon({ messages, key })}
                    >
                      <img src={chatIconSrc} alt="ikona chatbota" />
                    </ChatBotIconWrapper>
                    {key === messages.length - 1 && <Gap gap="30px" />}
                  </Col>
                  <Gap gap="30px" mobileGap="15px" />
                  <Col grow={1}>
                    <MessageBox isUser={isUser}>
                      <Text
                        textColor={isUser ? 'darkBlue' : '#4760af'}
                        mobileLineHeight="30px"
                      >
                        <div dangerouslySetInnerHTML={{ __html: text }} />
                      </Text>
                    </MessageBox>
                    {key === messages.length - 1 && (
                      <>
                        <Gap gap="10px" />
                        <Time right={messages[messages.length - 1].isUser}>
                          {moment().format('H:mm')}
                        </Time>
                      </>
                    )}
                  </Col>
                </Row>
                <Gap gap="10px" />
              </Fragment>
            ))}
          </ScrollAbleWrapper>

          <Gap gap="35px" />

          <Container>
            {/* ANSWERS */}
            {showAnswersButtons && (
              <Row justifyContent="center" width="100%">
                <Grid>
                  {question.answers.map((answer, key) => (
                    <Fragment key={key}>
                      <AnswerButton
                        isSelected={selectedAnswers.includes(key)}
                        onClick={() =>
                          this.answer(answer, key, question.isSort)}
                      >
                        {answer.text}
                      </AnswerButton>
                      {/* {key + 1 !== question.answers.length && (
                        <Gap gap="10px" />
                      )} */}
                    </Fragment>
                  ))}
                </Grid>
              </Row>
            )}

            {/* START BUTTONS */}
            {!isRunning && (
              <Row
                justifyContent="flex-end"
                mobileFlexDirection="column-reverse"
                width="100%"
                responsive
                bp="mobile"
              >
                {/* <Button.Transparent>NĚKDY PŘÍŠTĚ</Button.Transparent> */}
                {/* <Gap gap="30px" /> */}
                <Button onClick={this.start}>
                  <Row alignItems="center" justifyContent="center">
                    <img src={checkSrc} alt="" />
                    <Gap gap="15px" />
                    Jdu do toho
                  </Row>
                </Button>
              </Row>
            )}
            {isFinished && (
              <Row
                justifyContent="flex-end"
                mobileFlexDirection="column-reverse"
                width="100%"
                responsive
                bp="mobile"
              >
                <Button onClick={this.restart}>Opakovat kvíz</Button>
              </Row>
            )}
          </Container>
        </Col>
      </Wrapper>
    )
  }
}

export default ChatBot
