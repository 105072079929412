import React from 'react'
import styled, { css } from 'styled-components'
import { up, addResponsivity, addPagePadding } from '../lib/styles'

const Col = styled.div`
  ${({
    theme: { colors },
    responsive,
    justifyContent,
    alignItems = 'flex-start',
    alignSelf,
    mobileAlignSelf,
    mobileAlignItems,
    position,
    maxWidth,
    mobileMaxWidth,
    minWidth,
    maxHeight,
    width,
    mobileWidth,
    flex,
    grow,
    basis,
    shrink,
    padding,
    margin,
    mobilePadding,
    mobileMargin,
    height,
    withPagePadding,
    background,
    textAlign,
    zIndex,
    bp = 'mobile',
  }) => css`
    display: flex;
    align-items: ${mobileAlignItems || alignItems};
    justify-content: ${justifyContent};
    max-width: ${mobileMaxWidth || maxWidth};
    min-width: ${minWidth};
    max-height: ${maxHeight};
    width: ${mobileWidth || width};
    position: ${position};
    flex: ${flex};
    align-self: ${mobileAlignSelf || alignSelf};
    flex-grow: ${grow};
    flex-basis: ${basis};
    flex-shrink: ${shrink};
    padding: ${mobilePadding || padding};
    margin: ${mobileMargin || margin};
    height: ${height};
    background: ${background};
    text-align: ${textAlign};
    z-index: ${zIndex};

    flex-direction: ${(responsive ? 'row' : 'column')};

    ${up(bp)} {
      padding: ${padding};
      margin: ${margin};
      align-self: ${alignSelf};
      max-width: ${maxWidth};
      align-items: ${alignItems};
      width: ${width};
      flex-direction: column;
    }
    ${withPagePadding && addPagePadding()}
    ${addResponsivity({ displayAs: 'flex' })}
  `}
`

Col.Text = ({ narrow, ...props }) => (
  <Col maxWidth={narrow ? '540px' : '570px'} width="100%" {...props} />
)

export default Col
