import styled, { css } from 'styled-components'
import { up } from '../lib/styles'
import Accordion from './Accordion'

const FaqStyle = css`
  max-width: 570px;
  flex-grow: 1;
  /* flex-shrink: 10; */
  margin: 0 0 20px 0;
  flex-basis: unset;

  ${up('mobile')} {
    &:nth-child(odd) {
      margin: 0 30px 20px 0;
    }
    flex-basis: 570px;
  }
`

const Faq = styled(Accordion).attrs(() => ({
  isHtml: true,
}))`
 ${FaqStyle}
`

Faq.Dummy = styled.div`
  padding: 0 20px;

  ${FaqStyle}
`

export default Faq
