import React from 'react'
import styled, { css } from 'styled-components'
import { up } from '../lib/styles'

import WaterBgSrc from '../data/images/water-bg.svg'
import Waves from './Waves'

const IMG_SIZE = 204
const IMG_SIZE_MOBILE = 150

const isNumberSet = value => !!value || value === 0

const getSizeAndPosition = ({
  xCount,
  xCountMobile,
  yCount,
  yCountMobile,
  width = '100%',
  height = '100%',
  isMobile,
  top = 0,
  topMobile,
  left = 0,
  leftMobile,
  bottom,
  bottomMobile,
  right,
  rightMobile,
}) => {
  if (isMobile) {
    xCount = xCountMobile || xCount
    yCount = yCountMobile || yCount
    if (topMobile || topMobile === 0) top = topMobile
    if (bottomMobile || bottomMobile === 0) bottom = bottomMobile
    if (rightMobile || rightMobile === 0) right = rightMobile
    if (leftMobile || leftMobile === 0) left = leftMobile
  }
  const SIZE = isMobile ? IMG_SIZE_MOBILE : IMG_SIZE
  return css`
    height: ${yCount ? `${yCount * SIZE}px` : height};
    width: ${xCount ? `${xCount * SIZE}px` : width};

    top: ${isNumberSet(top) && !isNumberSet(bottom) ? top : 'unset'};
    left: ${isNumberSet(left) && !isNumberSet(right) ? left : 'unset'};
    right: ${isNumberSet(right) ? right : 'unset'};
    bottom: ${isNumberSet(bottom) ? bottom : 'unset'};
  `
}

const TapsBgComponent = styled.div`
  ${({
    theme: { colors },
    inverted,
    transparent,
    bp = 'tablet',
    zIndex = -10,
    ...rest
  }) => css`
    position: absolute;
    ${getSizeAndPosition({ ...rest, isMobile: true })}
    background: ${!transparent && colors.backgroundBlue} url(${WaterBgSrc});
    background-size: ${IMG_SIZE_MOBILE}px;
    z-index: ${zIndex};

    ${up(bp)} {
      background-size: auto;
      ${getSizeAndPosition({ ...rest, isMobile: false })}
    }

    ${!transparent
      && css`
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: linear-gradient(
            ${inverted ? 'to bottom ' : 'to top'},
            rgba(255, 255, 255, 0) 0%,
            #d9f1ff 100%
          );
        }
      `}
  `}
`

const TapsBg = ({ wavedBottom, wavedTop, ...props }) => (
  <TapsBgComponent {...props}>
    {wavedTop && <Waves top />}
    {wavedBottom && <Waves />}
  </TapsBgComponent>
)

export default TapsBg
