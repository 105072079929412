import React from 'react'
import styled, { css } from 'styled-components'

import { up, addPagePadding } from '../lib/styles'
import App from './App'
import { Menu, Footer, Gap } from '../components'

const Page = styled.div`
  ${({ theme: { sizes } }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
    /* ${addPagePadding()} */
  `}
`

class DefaultLayout extends React.Component {
  render() {
    // these props are exactly same as pageProps injected by gatsby
    // console.log('this.props LAYOUT', this.props)
    const {
      location: { pathname },
      pageContext: { trimmedPath },
      children,
    } = this.props

    return (
      <App trimmedPath={trimmedPath}>
        <Page>
          <Menu pathname={pathname} />
          {children}
          <Gap gap="180px" />
          <Menu pathname={pathname} isBottom />
        </Page>
        {/* <Footer /> */}

      </App>
    )
  }
}

export default DefaultLayout
