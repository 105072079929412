import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import Text from './Text'
import Col from './Col'
import Gap from './Gap'

const TRANSITION = '0.3s'

const Chevron = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="12"
    viewBox="0 0 22 12"
  >
    <path
      fill="#20bf55"
      d="M11 9.565l9.277-9.27a1.01 1.01 0 0 1 1.427 1.427l-9.99 9.983a1.01 1.01 0 0 1-1.428 0L.296 1.722A1.008 1.008 0 1 1 1.723.295z"
    />
  </svg>
)

const HtmlContent = styled.div`
  a {
    font-weight: 700;
  }
`

const Wrapper = styled.div`
  max-width: 570px;
  padding: 0 20px;
  box-shadow: 0 10px 60px -10px rgba(4, 62, 134, 0.2);
  border-radius: 6px;
  background-color: white;
`

const TitleWrapper = styled.div`
  min-height: 90px;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const ContentWrapper = styled.div`
  ${({ height }) => css`
    overflow: hidden;
    transition: ${TRANSITION};
    max-height: ${height}px;
  `}
`

const ChevronWrapper = styled.div`
  ${({ isActive }) => css`
    transform: rotate(${isActive ? 180 : 0}deg);
    transition: ${TRANSITION};
    padding-left: 15px;
  `}
`

const Accordion = ({ title, content, isHtml, ...props }) => {
  const [contentHeight, setContentHeight] = useState(0)
  const contentRef = useRef(null)
  const toggleAccordion = () =>
    contentRef.current
    && setContentHeight(contentHeight === 0 ? contentRef.current.scrollHeight : 0)
  return (
    <Wrapper {...props}>
      <TitleWrapper onClick={toggleAccordion}>
        <Col grow={1}>
          <Text.HighLight>{title}</Text.HighLight>
        </Col>
        <Col>
          <ChevronWrapper isActive={contentHeight !== 0}>
            <Chevron />
          </ChevronWrapper>
        </Col>
      </TitleWrapper>
      <ContentWrapper ref={contentRef} height={contentHeight}>
        {isHtml ? (
          <Text>
            <HtmlContent dangerouslySetInnerHTML={{ __html: content }} />{' '}
          </Text>
        ) : (
          <Text>{content}</Text>
        )}
        <Gap gap="24px" />
      </ContentWrapper>
    </Wrapper>
  )
}

export default Accordion
