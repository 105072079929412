import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'

import Box from './Box'
import Text from './Text'
import Row from './Row'
import Col from './Col'

import { regions } from '../lib/config'

const regionsWithCountry = [{ label: 'Celá ČR' }, ...regions]

const Chevron = props => (
  <svg
    width="20"
    height="10"
    viewBox="0 0 20 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5465 0.342147C18.186 -0.18449 19.1313 -0.0930064 19.6579 0.546482C20.1846 1.18597 20.0931 2.1313 19.4536 2.65794L10.9536 9.65794C10.3997 10.1141 9.60036 10.1141 9.04648 9.65794L0.546482 2.65794C-0.0930064 2.1313 -0.18449 1.18597 0.342147 0.546482C0.868784 -0.0930064 1.81412 -0.18449 2.4536 0.342147L10 6.55686L17.5465 0.342147Z"
      fill="#0099FC"
    />
  </svg>
)

const StyledText = props => (
  <Text fontSize="20px" textColor="textBlue" fontWeight="700" {...props} />
)
const ItemText = props => (
  <Text fontSize="20px" textColor="inherit" fontWeight="700" {...props} />
)

const itemPadding = '0 10px'

const SelectedItem = styled.div`
  position: relative;
  cursor: pointer;
  padding: ${itemPadding};
`
const TRANSITION = '0.3s'

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(0, 55, 128, 0.15);
  margin: 10px 0;
`

const ITEMS_MAX_HEIGHT = 300
const Items = styled.div`
  ${({ theme: { colors }, height = '0' }) => css`
    max-height: ${height > ITEMS_MAX_HEIGHT ? ITEMS_MAX_HEIGHT : height}px;
    transition: ${TRANSITION};
    overflow: ${height > 0 ? 'auto' : 'hidden'};
    z-index: 600;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: ${colors.backgroundBlue};
      border-radius: 6px
    }
    &::-webkit-scrollbar-thumb {
      background: ${colors.lightBlue};
      border-radius: 6px
    }
    /* &::-webkit-scrollbar-thumb:hover {
      background: ${colors.textBlue};
    } */
  `}
`
const ChevronWrapper = styled.div`
  ${({ isActive }) => css`
    transform: rotate(${isActive ? 180 : 0}deg);
    transition: ${TRANSITION};
  `}
`
const Item = styled.div`
  ${({ theme: { colors }, isSelected }) => css`
    width: 100%;
    cursor: pointer;
    color: ${colors.lightBlue};
    border-radius: 6px;
    padding: ${itemPadding};
    transition: 0.2s;
    &:hover {
      background: ${colors.backgroundBlue};
      color: ${colors.textBlue};
    }
    ${isSelected
      && css`
        background: ${colors.backgroundBlue};
        color: ${colors.textBlue};
      `}
  `}
`

const Wrapper = styled.div`
  ${({ maxWidth }) => css`
    position: relative;
    width: ${maxWidth};
    max-width: 100%;
  `}
`

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`
const Placeholder = styled.div`
  position: relative;
  width: 100%;
  height: 60px; // TODO sem by se měla nastavit výška zavřeného boxu, aby to fungovalo obecně
`

const Select = ({
  value,
  onChange,
  data,
  maxWidth = '370px',
  placeholder,
  ...props
}) => {
  // const [selectedIndex, setSelectedIndex] = useState(0)
  const [contentHeight, setContentHeight] = useState(0)

  const contentRef = useRef(null)

  const toggleMenu = () =>
    contentRef.current
    && setContentHeight(contentHeight === 0 ? contentRef.current.scrollHeight : 0)

  const selectItem = key => {
    onChange(key, data[key])
    setContentHeight(0)
  }

  const selectedItem = data[value] || {}
  const selectedItemLabel = selectedItem.label || placeholder || '---'

  return (
    <Wrapper maxWidth={maxWidth} {...props}>
      <Placeholder />
      <Content>
        <Box padding="11px 10px 9px">
          <SelectedItem onClick={toggleMenu}>
            <Row
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <StyledText>{selectedItemLabel}</StyledText>
              <ChevronWrapper isActive={contentHeight !== 0}>
                <Chevron />
              </ChevronWrapper>
            </Row>
          </SelectedItem>
          {contentHeight !== 0 && <Divider />}
          <Items ref={contentRef} height={contentHeight}>
            <Col>
              {data.map(({ label }, key) => (
                <Item
                  key={label}
                  onClick={() => selectItem(key)}
                  isSelected={key === value}
                >
                  <ItemText>{label}</ItemText>
                </Item>
              ))}
            </Col>
          </Items>
        </Box>
      </Content>
    </Wrapper>
  )
}

Select.Regions = ({ withCountry, ...props }) => (
  <Select data={withCountry ? regionsWithCountry : regions} {...props} />
)

export default Select
