const data = `Praha;Pražské vodovody a kanalizace, a.s.;48,30 Kč;39,09 Kč;54.77;46.82;101.59
Pražské vodovody a kanalizace, a.s.;;;;;;
Brno;Brněnské vodárny a kanalizace, a.s. (Brno);37,08 Kč;38,66 Kč;44.4;40.80;85.20
Brněnské vodárny a kanalizace, a.s. (Brno);;;;;;
Ostrava;Ostravské vodárny a kanalizace a.s.;37,65 Kč;39,65 Kč;41.09;43.57; 84,66
Ostravské vodárny a kanalizace a.s.;;;;;;
Plzeň;VODÁRNA PLZEŇ a.s. (Plzeň);52,58 Kč;33,56 Kč;60.58;38.23;98.81
VODÁRNA PLZEŇ a.s. (Plzeň);;;;;;
Liberec;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Olomouc;MORAVSKÁ VODÁRENSKÁ, a.s. (Olomoucko);38,40 Kč;44,87 Kč;40.63;48.55;89.18
MORAVSKÁ VODÁRENSKÁ, a.s. (Olomoucko);;;;;;
České Budějovice;ČEVAK a.s. (České Budějovice) - cena celkem přepočtená na m3;50,36 Kč;35,32 Kč;41.19;31.90;85.06
ČEVAK a.s. (České Budějovice) - cena celkem přepočtená na m3;;;;;;
Ústí nad Labem;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Hradec Králové;Královéhradecká provozní, a.s. - cena celkem přepočtená na m3;52,61 Kč;54,36 Kč;44.41;46.73;112.95
Královéhradecká provozní, a.s. - cena celkem přepočtená na m3;;;;;;
Pardubice;Vodovody a kanalizace Pardubice, a.s.;42,00 Kč;47,60 Kč;48.8;49.50;98.30
Vodovody a kanalizace Pardubice, a.s.;;;;;;
Zlín;MORAVSKÁ VODÁRENSKÁ, a.s. (Zlínsko);43,40 Kč;42,31 Kč;40.63;48.55;89.18
MORAVSKÁ VODÁRENSKÁ, a.s. (Zlínsko);;;;;;
Havířov;Severomoravské vodovody a kanalizace Ostrava, a.s.;42,64 Kč;38,43 Kč; 47,87;42.53; 90,40
Severomoravské vodovody a kanalizace Ostrava, a.s.;;;;;;
Kladno;Vodárny Kladno - Mělník a.s.;53,24 Kč;39,68 Kč;62,01 Kč;45.89;107.90
Středočeské vodárny, a.s. (Kladensko, Mělnicko);;;;;;
Most;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Opava;Severomoravské vodovody a kanalizace Ostrava, a.s.;42,64 Kč;38,43 Kč; 47,87;42.53; 90,40
Severomoravské vodovody a kanalizace Ostrava, a.s.;;;;;;
Frýdek-Místek;Severomoravské vodovody a kanalizace Ostrava, a.s.;42,64 Kč;38,43 Kč; 47,87;42.53; 90,40
Severomoravské vodovody a kanalizace Ostrava, a.s.;;;;;;
Karviná;Severomoravské vodovody a kanalizace Ostrava, a.s.;42,64 Kč;38,43 Kč; 47,87;42.53; 90,40
Severomoravské vodovody a kanalizace Ostrava, a.s.;;;;;;
Jihlava;VODÁRENSKÁ AKCIOVÁ SPOLEČNOST, a.s. (Jihlavsko);48,60 Kč;36,96 Kč;59.62;40.35; 99,97
Jihlavské vodovody a kanalizace, a.s.;;;;;;
Teplice;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Děčín;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Chomutov;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Karlovy Vary;Vodárny a kanalizace Karlovy Vary, a.s. - cena celkem přepočtená na m3;52,32 Kč;42,84 Kč;41.91;32.41;100.19
Vodárny a kanalizace Karlovy Vary, a.s. - cena celkem přepočtená na m3;;;;;;
Jablonec nad Nisou;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Mladá Boleslav;Vodovody a kanalizace Mladá Boleslav, a.s.;51,36 Kč;43,86 Kč;55.74;45.76;101.50
Vodovody a kanalizace Mladá Boleslav, a.s.;;;;;;
Prostějov;MORAVSKÁ VODÁRENSKÁ, a.s. (Prostějovsko);44,74 Kč;41,50 Kč;40.63;48.55;89.18
MORAVSKÁ VODÁRENSKÁ, a.s. (Prostějovsko);;;;;;
Přerov;Vodovody a kanalizace Přerov, a.s.;42,50 Kč;32,00 Kč;42.75;37.25;80.00
Vodovody a kanalizace Přerov, a.s.;;;;;;
Česká Lípa;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Třebíč;VODÁRENSKÁ AKCIOVÁ SPOLEČNOST, a.s. (svazek Třebíč);50,38 Kč;40,31 Kč;59.62;40.35; 99,97
VODÁRENSKÁ AKCIOVÁ SPOLEČNOST, a.s. (svazek Třebíč);;;;;;
Třinec;Severomoravské vodovody a kanalizace Ostrava, a.s.;42,64 Kč;38,43 Kč; 47,87;42.53; 90,40
Severomoravské vodovody a kanalizace Ostrava, a.s.;;;;;;
Tábor;ČEVAK a.s. (Tábor) - cena celkem přepočtená na m3;59,00 Kč;60,87 Kč;45.45;43.23;114.45
ČEVAK a.s. (Tábor) - cena celkem přepočtená na m3;;;;;;
Znojmo;VODÁRENSKÁ AKCIOVÁ SPOLEČNOST, a.s. (svazek Znojemsko);48,33 Kč;40,25 Kč;59.62;40.35; 99,97
VODÁRENSKÁ AKCIOVÁ SPOLEČNOST, a.s. (svazek Znojemsko);;;;;;
Příbram;1. SčV, a.s. (Příbram);52,23 Kč;23,14 Kč;54.51;33.20;87.71
1. SčV, a.s. (Příbram);;;;;;
Cheb;CHEVAK Cheb, a.s.;41,98 Kč;47,15 Kč;46.2;47.85;94.05
CHEVAK Cheb, a.s.;;;;;;
Kolín;VODOS s.r.o. (Kolín);39,08 Kč;34,12 Kč;46.45;42.20;88.65
VODOS s.r.o. (Kolín);;;;;;
Trutnov;Vodovody a kanalizace Trutnov, a.s.;43,49 Kč;32,81 Kč;48.64;34.67;83.31
Vodovody a kanalizace Trutnov, a.s.;;;;;;
Písek;ČEVAK a.s. (Písek);44,21 Kč;35,67 Kč;50.57; 34,84;85.41
ČEVAK a.s. (Písek);;;;;;
Orlová;Severomoravské vodovody a kanalizace Ostrava, a.s.;42,64 Kč;38,43 Kč; 47,87;42.53; 90,40
Severomoravské vodovody a kanalizace Ostrava, a.s.;;;;;;
Kroměříž;Vodovody a kanalizace Kroměříž, a.s.;36,98 Kč;39,88 Kč;40.15;43.12;83.27
Vodovody a kanalizace Kroměříž, a.s.;;;;;;
Vsetín;Vodovody a kanalizace Vsetín, a.s.;45,66 Kč;35,07 Kč;49.94;37.29;87.23
Vodovody a kanalizace Vsetín, a.s.;;;;;;
Šumperk;Šumperská provozní vodohospodářská společnost, a.s.;44,92 Kč;43,66 Kč;36.9;28.97;65.87
Šumperská provozní vodohospodářská společnost, a.s.;;;;;;
Uherské Hradiště;Slovácké vodárny a kanalizace, a.s.;40,48 Kč;38,06 Kč;43.56;41.14;84.70
Slovácké vodárny a kanalizace, a.s.;;;;;;
Břeclav;Vodovody a kanalizace Břeclav, a.s.;45,42 Kč;49,45 Kč;49.94;54.67;104.61
Vodovody a kanalizace Břeclav, a.s.;;;;;;
Hodonín;Vodovody a kanalizace Hodonín, a.s. - cena celkem přepočtená na m3;39,59 Kč;45,65 Kč;36.23;43.91;93.50
Vodovody a kanalizace Hodonín, a.s. - cena celkem přepočtená na m3;;;;;;
Český Těšín;Severomoravské vodovody a kanalizace Ostrava, a.s.;42,64 Kč;38,43 Kč; 47,87;42.53; 90,40
Severomoravské vodovody a kanalizace Ostrava, a.s.;;;;;;
Litoměřice;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Litvínov;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Nový Jičín;Severomoravské vodovody a kanalizace Ostrava, a.s.;42,64 Kč;38,43 Kč; 47,87;42.53; 90,40
Severomoravské vodovody a kanalizace Ostrava, a.s.;;;;;;
Krnov;Krnovské vodovody a kanalizace, s.r.o.;22,43 Kč;25,99 Kč;25.3;30.80;56.10
Krnovské vodovody a kanalizace, s.r.o.;;;;;;
Havlíčkův Brod;Vodovody a kanalizace Havlíčkův Brod, a.s.;40,74 Kč;32,25 Kč;42.48;35.59;78.07
Vodovody a kanalizace Havlíčkův Brod, a.s.;;;;;;
Sokolov;Vodohospodářská společnost Sokolov, s.r.o. (region Sokolovsko) - cena celkem přepočtená na m3;55,23 Kč;53,39 Kč;54.8;45.10;99.90
Vodohospodářská společnost Sokolov, s.r.o. (region Sokolovsko) - cena celkem přepočtená na m3;;;;;;
Chrudim;Vodárenská společnost Chrudim, a.s. - cena celkem přepočtená na m3;52,74 Kč;46,82 Kč;45.19;40.24;105.88
Vodárenská společnost Chrudim, a.s. - cena celkem přepočtená na m3;;;;;;
Strakonice;Technické služby Strakonice s.r.o.;58,72 Kč;35,78 Kč;64.5; 39,29;103.79
Technické služby Strakonice s.r.o.;;;;;;
Klatovy;Šumavské vodovody a kanalizace a.s.;35,20 Kč;28,46 Kč;36.9;28.97;65.87
Šumavské vodovody a kanalizace a.s.;;;;;;
Valašské Meziříčí;Vodovody a kanalizace Vsetín, a.s.;45,66 Kč;35,07 Kč;49.94;37.29;87.23
Vodovody a kanalizace Vsetín, a.s.;;;;;;
Kopřivnice;Severomoravské vodovody a kanalizace Ostrava, a.s.;42,64 Kč;38,43 Kč; 47,87;42.53; 90,40
Severomoravské vodovody a kanalizace Ostrava, a.s.;;;;;;
Jindřichův Hradec;ČEVAK a.s. (Jindřichův Hradec) - cena celkem přepočtená na m3;45,98 Kč;39,74 Kč;35.64;29.79;85.13
ČEVAK a.s. (Jindřichův Hradec) - cena celkem přepočtená na m3;;;;;;
Vyškov;Vodovody a kanalizace Vyškov, a.s.;45,68 Kč;41,72 Kč;50.81;45.41;96.22
Vodovody a kanalizace Vyškov, a.s.;;;;;;
Žďár nad Sázavou;VODÁRENSKÁ AKCIOVÁ SPOLEČNOST, a.s. (svazek Žďársko);54,50 Kč;40,40 Kč;59.62;40.35; 99,97
VODÁRENSKÁ AKCIOVÁ SPOLEČNOST, a.s. (svazek Žďársko);;;;;;
Bohumín;Severomoravské vodovody a kanalizace Ostrava, a.s.;42,64 Kč;38,43 Kč; 47,87;42.53; 90,40
Severomoravské vodovody a kanalizace Ostrava, a.s.;;;;;;
Kutná Hora;Vodohospodářská společnost Vrchlice-Maleč, a.s.;50,08 Kč;41,23 Kč;54.8;45.10;99.90
Vodohospodářská společnost Vrchlice-Maleč, a.s.;;;;;;
Blansko;VODÁRENSKÁ AKCIOVÁ SPOLEČNOST, a.s. (svazek Blansko);48,15 Kč;48,66 Kč;59.62;40.35; 99,97
VODÁRENSKÁ AKCIOVÁ SPOLEČNOST, a.s. (svazek Blansko);;;;;;
Náchod;Vodovody a kanalizace Náchod, a.s.;37,80 Kč;38,41 Kč;41.95;34.67;84.82
Vodovody a kanalizace Náchod, a.s.;;;;;;
Beroun;Vodovody a kanalizace Beroun, a.s. (Beroun);53,36 Kč;42,55 Kč;55.33;44.06;99.39
Vodovody a kanalizace Beroun, a.s. (Beroun);;;;;;
Mělník;Vodárny Kladno - Mělník a.s.;53,24 Kč;39,68 Kč;62,01 Kč;45.89;107.90
Vodárny Kladno - Mělník a.s.;;;;;;
Jirkov;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Brandýs nad Labem-Stará Boleslav;STAVOKOMPLET spol. s r.o. (Brandýs nad Labem - Stará Boleslav);41,70 Kč;40,82 Kč;48.49;44.25;92.74
STAVOKOMPLET spol. s r.o. (Brandýs nad Labem - Stará Boleslav);;;;;;
Žatec;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Louny;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Kadaň;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Kralupy nad Vltavou;Severočeská vodárenská společnost;53,24 Kč;39,68 Kč;53.77;51.00;104.77
Středočeské vodárny, a.s. (Kladensko, Mělnicko);;;;;;
Hranice;Vodovody a kanalizace Přerov, a.s.;42,50 Kč;32,00 Kč;42.75;37.25;80.00
Vodovody a kanalizace Přerov, a.s.;;;;;;
Otrokovice;MORAVSKÁ VODÁRENSKÁ, a.s. (Zlínsko);43,40 Kč;42,31 Kč;40.63;48.55;89.18
MORAVSKÁ VODÁRENSKÁ, a.s. (Zlínsko);;;;;;
Bílina;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Svitavy;Vodárenská Svitavy s.r.o.;37,98 Kč;43,38 Kč;37.96;45.21;83.17
Vodárenská Svitavy s.r.o.;;;;;;
Ostrov;Vodárny a kanalizace Karlovy Vary, a.s. - cena celkem přepočtená na m3;52,32 Kč;42,84 Kč;41.91;32.41;100.19
Vodárny a kanalizace Karlovy Vary, a.s. - cena celkem přepočtená na m3;;;;;;
Benešov;Vodohospodářská společnost Benešov, s.r.o. (Benešov);43,70 Kč;39,97 Kč;54.8;45.10;99.90
Vodohospodářská společnost Benešov, s.r.o. (Benešov);;;;;;
Jičín;Vodohospodářská a obchodní společnost, a.s.;38,74 Kč;40,21 Kč;42.47;42.42;84.89
Vodohospodářská a obchodní společnost, a.s.;;;;;;
Uherský Brod;Slovácké vodárny a kanalizace, a.s.;40,48 Kč;38,06 Kč;43.56;41.14;84.70
Slovácké vodárny a kanalizace, a.s.;;;;;;
Rožnov pod Radhoštěm;Vodovody a kanalizace Vsetín, a.s.;45,66 Kč;35,07 Kč;49.94;37.29;87.23
Vodovody a kanalizace Vsetín, a.s.;;;;;;
Bruntál;VaK Bruntál a.s.;40,94 Kč;38,43 Kč;41.59;41.29;82.88
VaK Bruntál a.s.;;;;;;
Neratovice;Vodárny Kladno - Mělník a.s.;53,24 Kč;39,68 Kč;62,01 Kč;45.89;107.90
Vodárny Kladno - Mělník a.s.;;;;;;
Pelhřimov;VODAK Humpolec, s.r.o. (Humpolec);48,80 Kč;20,24 Kč;41.75;36.90;78.65
VODAK Humpolec, s.r.o. (Humpolec);;;;;;
Rakovník;RAVOS, s.r.o.;42,79 Kč;38,26 Kč;47.33;42.66;89.99
RAVOS, s.r.o.;;;;;;
Slaný;Slavos Slaný, s.r.o.;54,06 Kč;30,41 Kč;65.31;32.71;98.02
Slavos Slaný, s.r.o.;;;;;;
Říčany;1. SčV, a.s. (Říčany);42,92 Kč;40,28 Kč;50.08;42.11;92.19
1. SčV, a.s. (Říčany);;;;;;
Dvůr Králové nad Labem;Městské vodovody a kanalizace Dvůr Králové nad Labem s.r.o.;35,36 Kč;48,47 Kč;33.97;57.33; 91,30
Městské vodovody a kanalizace Dvůr Králové nad Labem s.r.o.;;;;;;
Česká Třebová;Vodárenská společnost Česká Třebová;36,57 Kč;32,55 Kč;38.39;35.31;73.70
Vodárenská společnost Česká Třebová;;;;;;
Varnsdorf;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Nymburk;Vodovody a kanalizace Nymburk, a.s. (lokalita: akciová společnost);41,18 Kč;44,22 Kč;33.39;49.06;88.45
Vodovody a kanalizace Nymburk, a.s. (lokalita: akciová společnost);;;;;;
Klášterec nad Ohří;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Turnov;Severočeská vodárenská společnost;49,98 Kč;48,69 Kč;53.77;51.00;104.77
Severočeské vodovody a kanalizace, a.s.;;;;;;
Ústí nad Orlicí;Vodovody a kanalizace Jablonné nad Orlicí, a.s.;39,49 Kč;42,44 Kč;42.57;45.76;88.33
Vodovody a kanalizace Jablonné nad Orlicí, a.s.;;;;;;
Rokycany;Vodohospodářská společnost Sokolov, s.r.o. (Město Rokycany);55,02 Kč;38,42 Kč;54.8;45.10;99.90
Vodohospodářská společnost Sokolov, s.r.o. (Město Rokycany);;;;;;
Poděbrady;Vodovody a kanalizace Nymburk, a.s. (lokalita: akciová společnost);41,18 Kč;44,22 Kč;33.39;49.06;88.45
Vodovody a kanalizace Nymburk, a.s. (lokalita: akciová společnost);;;;;;
Hlučín;Vodovody a kanalizace Hlučín s.r.o.;37,20 Kč;41,26 Kč;36.69;39.86;76.55
Vodovody a kanalizace Hlučín s.r.o.;;;;;;
Zábřeh;Šumperská provozní vodohospodářská společnost, a.s.;44,92 Kč;43,66 Kč;36.9;28.97;65.87
Šumperská provozní vodohospodářská společnost, a.s.;;;;;;
Šternberk;VHS SITKA, s.r.o. (Šternberk);29,32 Kč;40,83 Kč;31.35;43.45;74.80
VHS SITKA, s.r.o. (Šternberk);;;;;;
Chodov;Vodárny a kanalizace Karlovy Vary, a.s. - cena celkem přepočtená na m3;52,32 Kč;42,84 Kč;41.91;32.41;100.19
Vodárny a kanalizace Karlovy Vary, a.s. - cena celkem přepočtená na m3;;;;;;
Aš;CHEVAK Cheb, a.s.;41,98 Kč;47,15 Kč;46.2;47.85;94.05
CHEVAK Cheb, a.s.;;;;;;
Český Krumlov;ČEVAK a.s. (Český Krumlov) - cena celkem přepočtená na m3;43,70 Kč;44,47 Kč;37.27;49.02;101.97
ČEVAK a.s. (Český Krumlov) - cena celkem přepočtená na m3;;;;;;`

const rows = data.split('\n').map(row => row.split(';'))

const mapConf = {
  0: 'city',
  1: 'distributor',
  4: 'invoicedPrice',
  5: 'dischargedPrice',
}

const sumPrice = (first, second) => {
  const x = parseFloat(first)
  const y = parseFloat(second)
  const sum = x + y
  const rounded = Math.round(sum * 100) / 100
  return rounded.toFixed(2).replace('.', ',')
}

// distributor je vždy na prvním místě na druhém řádku

const priceList = rows.reduce((result, row, key) => {
  if (key % 2 === 0) {
    // normální řádek
    const parsedRow = row.reduce((acc, col, key) => {
      const propName = mapConf[key]
      if (!propName) return acc
      acc[propName] = col
      return acc
    }, {})
    result.push(parsedRow)
    return result
  }
  // pouze distributor
  // const distributor = row[0]
  // result[result.length - 1].distributor = distributor
  return result
}, []).map((row, key) => ({
  ...row,
  id: key,
  sum: sumPrice(row.invoicedPrice, row.dischargedPrice),
}))

export default priceList
