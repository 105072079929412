import React from 'react'
import styled, { css } from 'styled-components'
import { up, addResponsivity } from '../lib/styles'

// default values belong to Page title
const TitleComponent = styled.span.attrs(props => ({
  as: props.as || 'h2',
}))`
  ${({
    theme: { colors, fonts },
    display = 'block',
    fontSize = '54px',
    mobileFontSize,
    fontWeight = 700,
    mobileFontWeight,
    lineHeight = '68px',
    mobileLineHeight,
    textColor,
    textAlign,
    mobileTextAlign,
    bp = 'mobile', // can be px or bp name

    maxWidth = '780px',
    alignSelf,
    margin = 0,
  }) => css`
    font-family: ${fonts.venti};
    font-size: ${mobileFontSize || fontSize};
    font-weight: ${mobileFontWeight || fontWeight};
    line-height: ${mobileLineHeight || lineHeight};
    color: ${colors[textColor] || textColor || colors.darkBlue};
    text-align: ${mobileTextAlign || textAlign};
    display: ${display};

    max-width: ${maxWidth};
    align-self: ${alignSelf};
    margin: ${margin};

    ${up(bp)} {
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      line-height: ${lineHeight};
      text-align: ${textAlign};
    }

    strong {
      font-weight: 700;
    }

    span {
      font-size: 30px;
      font-weight: 500;
    }

    ${addResponsivity({ displayAs: display })}
  `}
`

const Title = props => (
  <TitleComponent
    fontSize="54px"
    lineHeight="68px"
    mobileFontSize="42px"
    mobileLineHeight="52px"
    bp="mobile"
    {...props}
  />
)

Title.Long = props => (
  <TitleComponent
    fontSize="54px"
    lineHeight="68px"
    mobileFontSize="32px"
    mobileLineHeight="42px"
    {...props}
  />
)

Title.Main = props => (
  <TitleComponent
    as="h1"
    fontSize="80px"
    lineHeight="80px"
    mobileFontSize="50px"
    alignSelf="center"
    {...props}
  />
)

Title.Article = props => (
  <TitleComponent
    as="h1"
    fontSize="44px"
    lineHeight="55px"
    mobileFontSize="40px"
    mobileLineHeight="50px"
    alignSelf="center"
    {...props}
  />
)

Title.Sub = props => (
  <TitleComponent
    as="h3"
    fontSize="36px"
    lineHeight="48px"
    mobileFontSize="28px"
    mobileLineHeight="42px"
    fontWeight="500"
    textColor="lightBlue"
    {...props}
  />
)

Title.Small = (props) => (
  <TitleComponent
    as="span"
    fontSize="28px"
    lineHeight="36px"
    mobileFontSize="26px"
    mobileLineHeight="34px"
    textColor="textBlue"
    {...props}
  />
)

Title.DataAmountSmall = (props) => (
  <TitleComponent
    as="span"
    fontSize="40px"
    lineHeight="40px"
    {...props}
  />
)
Title.DataAmount = (props) => (
  <TitleComponent
    as="span"
    fontSize="54px"
    lineHeight="60px"
    textColor="lightBlue"
    {...props}
  />
)
Title.DataAmountLarge = (props) => (
  <TitleComponent
    as="span"
    fontSize="120px"
    mobileFontSize="100px"
    lineHeight="100px"
    textColor="lightBlue"
    {...props}
  />
)

export default Title
