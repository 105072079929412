import priceList2021update from './priceList2021update'

const data = `1	CZ0100	554782	Praha	Hlavní město Praha	x	1 308 632	Pražské vodovody a kanalizace, a.s.	48,30	39,09	
2	CZ0642	582786	Brno	Jihomoravský	x	380 681	Brněnské vodárny a kanalizace, a.s. (Brno)	37,08	38,66	
3	CZ0806	554821	Ostrava	Moravskoslezský	x	289 128	Ostravské vodárny a kanalizace a.s.	37,65	39,65	
4	CZ0323	554791	Plzeň	Plzeňský	x	172 441	VODÁRNA PLZEŇ a.s. (Plzeň)	52,58	33,56	
5	CZ0513	563889	Liberec	Liberecký	x	104 445	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
6	CZ0712	500496	Olomouc	Olomoucký	x	100 523	MORAVSKÁ VODÁRENSKÁ, a.s. (Olomoucko)	38,40	44,87	
7	CZ0311	544256	České Budějovice	Jihočeský	x	94 014	ČEVAK a.s. (České Budějovice) - cena celkem přepočtená na m3	50,36	35,32	x
8	CZ0427	554804	Ústí nad Labem	Ústecký	x	92 952	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
9	CZ0521	569810	Hradec Králové	Královéhradecký	x	92 742	Královéhradecká provozní, a.s. - cena celkem přepočtená na m3	52,61	54,36	x
10	CZ0532	555134	Pardubice	Pardubický	x	90 688	Vodovody a kanalizace Pardubice, a.s.	42,00	47,60	
11	CZ0724	585068	Zlín	Zlínský	x	74 997	MORAVSKÁ VODÁRENSKÁ, a.s. (Zlínsko)	43,40	42,31	
12	CZ0803	555088	Havířov	Moravskoslezský		71 903	Severomoravské vodovody a kanalizace Ostrava, a.s.	42,64	38,43	
13	CZ0203	532053	Kladno	Středočeský	x	69 054	Středočeské vodárny, a.s. (Kladensko, Mělnicko)	53,24	39,68	
14	CZ0425	567027	Most	Ústecký	x	66 186	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
15	CZ0805	505927	Opava	Moravskoslezský	x	56 638	Severomoravské vodovody a kanalizace Ostrava, a.s.	42,64	38,43	
16	CZ0802	598003	Frýdek-Místek	Moravskoslezský	x	55 931	Severomoravské vodovody a kanalizace Ostrava, a.s.	42,64	38,43	
17	CZ0803	598917	Karviná	Moravskoslezský	x	52 824	Severomoravské vodovody a kanalizace Ostrava, a.s.	42,64	38,43	
18	CZ0632	586846	Jihlava	Vysočina	x	50 845	Jihlavské vodovody a kanalizace, a.s.	48,60	36,96	
19	CZ0426	567442	Teplice	Ústecký	x	49 575	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
20	CZ0421	562335	Děčín	Ústecký	x	48 809	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
21	CZ0422	562971	Chomutov	Ústecký	x	48 720	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
22	CZ0412	554961	Karlovy Vary	Karlovarský	x	48 501	Vodárny a kanalizace Karlovy Vary, a.s. - cena celkem přepočtená na m3	52,32	42,84	x
23	CZ0512	563510	Jablonec nad Nisou	Liberecký	x	45 802	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
24	CZ0207	535419	Mladá Boleslav	Středočeský	x	44 489	Vodovody a kanalizace Mladá Boleslav, a.s.	51,36	43,86	
25	CZ0713	589250	Prostějov	Olomoucký	x	43 680	MORAVSKÁ VODÁRENSKÁ, a.s. (Prostějovsko)	44,74	41,50	
26	CZ0714	511382	Přerov	Olomoucký	x	43 186	Vodovody a kanalizace Přerov, a.s.	42,50	32,00	
27	CZ0511	561380	Česká Lípa	Liberecký	x	37 444	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
28	CZ0634	590266	Třebíč	Vysočina	x	35 691	VODÁRENSKÁ AKCIOVÁ SPOLEČNOST, a.s. (svazek Třebíč)	50,38	40,31	
29	CZ0802	598810	Třinec	Moravskoslezský		35 131	Severomoravské vodovody a kanalizace Ostrava, a.s.	42,64	38,43	
30	CZ0317	552046	Tábor	Jihočeský	x	34 456	ČEVAK a.s. (Tábor) - cena celkem přepočtená na m3	59,00	60,87	x
31	CZ0647	593711	Znojmo	Jihomoravský	x	33 780	VODÁRENSKÁ AKCIOVÁ SPOLEČNOST, a.s. (svazek Znojemsko)	48,33	40,25	
32	CZ020B	539911	Příbram	Středočeský	x	32 642	1. SčV, a.s. (Příbram)	52,23	23,14	
33	CZ0411	554481	Cheb	Karlovarský	x	31 988	CHEVAK Cheb, a.s.	41,98	47,15	
34	CZ0204	533165	Kolín	Středočeský	x	31 690	VODOS s.r.o. (Kolín)	39,08	34,12	
35	CZ0525	579025	Trutnov	Královéhradecký	x	30 372	Vodovody a kanalizace Trutnov, a.s.	43,49	32,81	
36	CZ0314	549240	Písek	Jihočeský	x	30 351	ČEVAK a.s. (Písek)	44,21	35,67	
37	CZ0803	599069	Orlová	Moravskoslezský		28 852	Severomoravské vodovody a kanalizace Ostrava, a.s.	42,64	38,43	
38	CZ0721	588296	Kroměříž	Zlínský	x	28 816	Vodovody a kanalizace Kroměříž, a.s.	36,98	39,88	
39	CZ0723	541630	Vsetín	Zlínský	x	26 092	Vodovody a kanalizace Vsetín, a.s.	45,66	35,07	
40	CZ0715	523704	Šumperk	Olomoucký	x	25 957	Šumperská provozní vodohospodářská společnost, a.s.	44,92	43,66	
41	CZ0722	592005	Uherské Hradiště	Zlínský	x	25 212	Slovácké vodárny a kanalizace, a.s.	40,48	38,06	
42	CZ0644	584291	Břeclav	Jihomoravský	x	24 704	Vodovody a kanalizace Břeclav, a.s. 	45,42	49,45	
43	CZ0645	586021	Hodonín	Jihomoravský	x	24 682	Vodovody a kanalizace Hodonín, a.s. - cena celkem přepočtená na m3	39,59	45,65	x
44	CZ0803	598933	Český Těšín	Moravskoslezský		24 438	Severomoravské vodovody a kanalizace Ostrava, a.s.	42,64	38,43	
45	CZ0423	564567	Litoměřice	Ústecký	x	24 001	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
46	CZ0425	567256	Litvínov	Ústecký		23 884	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
47	CZ0804	599191	Nový Jičín	Moravskoslezský	x	23 496	Severomoravské vodovody a kanalizace Ostrava, a.s.	42,64	38,43	
48	CZ0801	597520	Krnov	Moravskoslezský		23 397	Krnovské vodovody a kanalizace, s.r.o.	22,43	25,99	
49	CZ0631	568414	Havlíčkův Brod	Vysočina	x	23 256	Vodovody a kanalizace Havlíčkův Brod, a.s.	40,74	32,25	
50	CZ0413	560286	Sokolov	Karlovarský	x	23 241	Vodohospodářská společnost Sokolov, s.r.o. (region Sokolovsko) - cena celkem přepočtená na m3	55,23	53,39	x
51	CZ0531	571164	Chrudim	Pardubický	x	23 151	Vodárenská společnost Chrudim, a.s. - cena celkem přepočtená na m3	52,74	46,82	x
52	CZ0316	550787	Strakonice	Jihočeský	x	22 754	Technické služby Strakonice s.r.o.	58,72	35,78	
53	CZ0322	555771	Klatovy	Plzeňský	x	22 233	Šumavské vodovody a kanalizace a.s.	35,20	28,46	
54	CZ0723	545058	Valašské Meziříčí	Zlínský		22 217	Vodovody a kanalizace Vsetín, a.s.	45,66	35,07	
55	CZ0804	599565	Kopřivnice	Moravskoslezský		21 949	Severomoravské vodovody a kanalizace Ostrava, a.s.	42,64	38,43	
56	CZ0313	545881	Jindřichův Hradec	Jihočeský	x	21 445	ČEVAK a.s. (Jindřichův Hradec) - cena celkem přepočtená na m3	45,98	39,74	x
57	CZ0646	592889	Vyškov	Jihomoravský	x	20 883	Vodovody a kanalizace Vyškov, a.s.	45,68	41,72	
58	CZ0635	595209	Žďár nad Sázavou	Vysočina	x	20 847	VODÁRENSKÁ AKCIOVÁ SPOLEČNOST, a.s. (svazek Žďársko)	54,50	40,40	
59	CZ0803	599051	Bohumín	Moravskoslezský		20 690	Severomoravské vodovody a kanalizace Ostrava, a.s.	42,64	38,43	
60	CZ0205	533955	Kutná Hora	Středočeský	x	20 580	Vodohospodářská společnost Vrchlice-Maleč, a.s.	50,08	41,23	
61	CZ0641	581283	Blansko	Jihomoravský	x	20 572	VODÁRENSKÁ AKCIOVÁ SPOLEČNOST, a.s. (svazek Blansko)	48,15	48,66	
62	CZ0523	573868	Náchod	Královéhradecký	x	19 979	Vodovody a kanalizace Náchod, a.s.	37,80	38,41	
63	CZ0202	531057	Beroun	Středočeský	x	19 510	Vodovody a kanalizace Beroun, a.s. (Beroun)	53,36	42,55	
64	CZ0206	534676	Mělník	Středočeský	x	19 486	Středočeské vodárny, a.s. (Kladensko, Mělnicko)	53,24	39,68	
65	CZ0422	563099	Jirkov	Ústecký		19 299	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
66	CZ0209	538094	Brandýs nad Labem-Stará Boleslav	Středočeský		19 136	STAVOKOMPLET spol. s r.o. (Brandýs nad Labem - Stará Boleslav)	41,70	40,82	
67	CZ0424	566985	Žatec	Ústecký		19 133	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
68	CZ0424	565971	Louny	Ústecký	x	18 351	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
69	CZ0422	563102	Kadaň	Ústecký		18 202	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
70	CZ0206	534951	Kralupy nad Vltavou	Středočeský		18 194	Středočeské vodárny, a.s. (Kladensko, Mělnicko)	53,24	39,68	
71	CZ0714	513750	Hranice	Olomoucký		18 057	Vodovody a kanalizace Přerov, a.s.	42,50	32,00	
72	CZ0724	585599	Otrokovice	Zlínský		17 876	MORAVSKÁ VODÁRENSKÁ, a.s. (Zlínsko)	43,40	42,31	
73	CZ0426	567451	Bílina	Ústecký		17 166	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
74	CZ0533	577731	Svitavy	Pardubický	x	16 838	Vodárenská Svitavy s.r.o.	37,98	43,38	
75	CZ0412	555428	Ostrov	Karlovarský		16 731	Vodárny a kanalizace Karlovy Vary, a.s. - cena celkem přepočtená na m3	52,32	42,84	x
76	CZ0201	529303	Benešov	Středočeský	x	16 656	Vodohospodářská společnost Benešov, s.r.o. (Benešov)	43,70	39,97	
77	CZ0522	572659	Jičín	Královéhradecký	x	16 577	Vodohospodářská a obchodní společnost, a.s.	38,74	40,21	
78	CZ0722	592731	Uherský Brod	Zlínský		16 493	Slovácké vodárny a kanalizace, a.s.	40,48	38,06	
79	CZ0723	544841	Rožnov pod Radhoštěm	Zlínský		16 420	Vodovody a kanalizace Vsetín, a.s.	45,66	35,07	
80	CZ0801	597180	Bruntál	Moravskoslezský	x	16 408	VaK Bruntál a.s.	40,94	38,43	
81	CZ0206	535087	Neratovice	Středočeský		16 163	Středočeské vodárny, a.s. (Kladensko, Mělnicko)	53,24	39,68	
82	CZ0633	547492	Pelhřimov	Vysočina	x	16 069	VODAK Humpolec, s.r.o. (Humpolec)	48,80	20,24	
83	CZ020C	541656	Rakovník	Středočeský	x	15 846	RAVOS, s.r.o.	42,79	38,26	
84	CZ0203	532819	Slaný	Středočeský		15 834	Středočeské vodárny, a.s. (Slánsko)	54,06	30,41	
85	CZ0209	538728	Říčany	Středočeský		15 619	1. SčV, a.s. (Říčany)	42,92	40,28	
86	CZ0525	579203	Dvůr Králové nad Labem	Královéhradecký		15 594	Městské vodovody a kanalizace Dvůr Králové nad Labem s.r.o.	35,36	48,47	
87	CZ0534	580031	Česká Třebová	Pardubický		15 508	Vodárenská společnost Česká Třebová	36,57	32,55	
88	CZ0421	562882	Varnsdorf	Ústecký		15 297	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
89	CZ0208	537004	Nymburk	Středočeský	x	15 063	Vodovody a kanalizace Nymburk, a.s. (lokalita: akciová společnost)	41,18	44,22	
90	CZ0422	563129	Klášterec nad Ohří	Ústecký		14 533	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
91	CZ0514	577626	Turnov	Liberecký		14 334	Severočeské vodovody a kanalizace, a.s.	49,98	48,69	
92	CZ0534	579891	Ústí nad Orlicí	Pardubický	x	14 196	Vodovody a kanalizace Jablonné nad Orlicí, a.s.	39,49	42,44	
93	CZ0326	559717	Rokycany	Plzeňský	x	14 192	Vodohospodářská společnost Sokolov, s.r.o. (Město Rokycany)	55,02	38,42	
94	CZ0208	537683	Poděbrady	Středočeský		14 186	Vodovody a kanalizace Nymburk, a.s. (lokalita: akciová společnost)	41,18	44,22	
95	CZ0805	507016	Hlučín	Moravskoslezský		13 953	Vodovody a kanalizace Hlučín s.r.o.	37,20	41,26	
96	CZ0715	541354	Zábřeh	Olomoucký		13 589	Šumperská provozní vodohospodářská společnost, a.s.	44,92	43,66	
97	CZ0712	505188	Šternberk	Olomoucký		13 495	VHS SITKA, s.r.o. (Šternberk)	29,32	40,83	
98	CZ0413	560383	Chodov	Karlovarský		13 394	Vodárny a kanalizace Karlovy Vary, a.s. - cena celkem přepočtená na m3	52,32	42,84	x
99	CZ0411	554499	Aš	Karlovarský		13 210	CHEVAK Cheb, a.s.	41,98	47,15	
100	CZ0312	545392	Český Krumlov	Jihočeský	x	13 085	ČEVAK a.s. (Český Krumlov) - cena celkem přepočtená na m3	43,70	44,47	x`

const rows = data.split('\n').map(row => row.split('\t'))

const mapConf = {
  3: 'city',
  4: 'region',
  7: 'distributor',
  8: 'invoicedPrice',
  9: 'dischargedPrice',
  10: 'isDoublePrice',
}

const sumPrice = (first, second) => {
  const x = parseFloat(first)
  const y = parseFloat(second)
  const sum = x + y
  const rounded = Math.round(sum * 100) / 100
  return rounded.toFixed(2).replace('.', ',')
}

const priceList = rows
  .map(row =>
    row.reduce((acc, col, key) => {
      const propName = mapConf[key]
      if (!propName) return acc
      acc[propName] = col
      return acc
    }, {}))
  .map((row, key) => ({
    ...row,
    id: key,
    isDoublePrice: !!row.isDoublePrice,
    sum: sumPrice(row.invoicedPrice, row.dischargedPrice),
  }))

const updatedPriceList = priceList.map((original, key) => ({ ...original, ...priceList2021update[key] }))

export const sortedPricesList = [...updatedPriceList].sort((a, b) => a.city.localeCompare(b.city))

export default updatedPriceList
