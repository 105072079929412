import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { createGlobalStyle, ThemeProvider, css } from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import smoothscroll from 'smoothscroll-polyfill'
import styledNormalize from 'styled-normalize'
import { theme } from '../lib/styles'
import fontFiles from '../lib/fonts'
import '../lib/fragments' // has to be imported somewhere to gatsby notice that

const GlobalStyle = createGlobalStyle`${({ theme: { colors, fonts } }) => css`
  ${styledNormalize}
  ${fontsDef}

  body {
    font-family: ${fonts.nunito};
    color: ${colors.text};
    font-size: 0;
  }
  * {
    box-sizing: border-box;
  }
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`}`

class App extends React.Component {
  componentDidMount() {
    smoothscroll.polyfill()
  }

  componentDidCatch(e) {
    console.log('DID CATCH', e)
  }

  render() {
    const { children } = this.props
    return (
      <ParallaxProvider>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            {children}
          </>
        </ThemeProvider>
      </ParallaxProvider>
    )
  }
}

export default App

const fontsDef = css`
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Nunito Sans'),
      url(${fontFiles.NunitoSansLight}) format('truetype');
  }
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Nunito Sans'),
      url(${fontFiles.NunitoSansRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Nunito Sans'),
      url(${fontFiles.NunitoSansBold}) format('truetype');
  }

  @font-face {
    font-family: 'VentiCF';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: local('VentiCF'), url(${fontFiles.VentiCFMedium}) format('woff2');
  }
  @font-face {
    font-family: 'VentiCF';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('VentiCF'), url(${fontFiles.VentiCFBold}) format('woff2');
  }
`
