import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  box-shadow: 0 10px 60px -10px rgba(4, 62, 134, 0.2);
  border-radius: 6px;
`
const TableComponent = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 6px;
  background-color: #ffffff;
  min-width: 630px;
`

const commonStyle = css`
  border-collapse: collapse;
`

class Table extends Component {
  componentDidMount() {
    // TODO
  }

  render() {
    return (
      <Wrapper>
        <TableComponent {...this.props} />
      </Wrapper>
    )
  }
}

Table.HeaderRow = styled.tr`
  ${commonStyle}
  border-radius: 6px 6px 0 0;
  background: #ecf8ff;
  cursor: pointer;
  /* min-height: 72px;
  height: 72px; */
`
Table.Header = styled.th`
  ${commonStyle}
  padding: 24px;
`
Table.Row = styled.tr`
  ${commonStyle} 

  /* min-height: 72px;
  
  height: 72px; */
`

Table.Col = styled.td`
  ${commonStyle}
  padding: 12px 24px;
`

export default Table
