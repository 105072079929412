import React from 'react'
import { Sup, Text } from '..'

const Question1 = {
  preText:
    'Skvělé! Tak se do toho pustíme. Připravte se, první otázka je tady:',
  text:
    '<b>1. Víte, kolik litrů představuje 1 m<sup>3</sup> vody? Vyberte správnou variantu.</b>',
  answers: [
    {
      text: 'a. 10 litrů',
    },
    {
      text: 'b. 100 litrů',
    },
    {
      text: 'c. 1000 litrů',
      isRight: true,
    },
    {
      text: 'd. 10 000 litrů',
    },
  ],
  attempts: 2,
  wrongAnswerMessages: [
    'Tohle je špatná odpověď. Zkuste to znovu.',
    'Zase vedle. 😊 Tohle je bohužel také špatná odpověď.<br/>Správná odpověď je c.',
  ],
  rightAnswerMessage: 'Výborně! Odpověděli jste správně.',
  finalInfo: `1 m<sup>3</sup> neboli 1 kubík vody představuje <b>1 000 litrů</b>.<br />
    Pamatujte si: 1 kubík vody = 1 000 litrů = 1 000 dm<sup>3</sup> = 1 m<sup>3</sup>`,
}

const Question2 = {
  preText: 'Pustíme se do další otázky…',
  text:
    '<b>2. Seřaďte státy podle průměrné denní spotřeby pitné vody (od nejnižší po nejvyšší).</b>',
  answers: [
    {
      text: 'a. Česká republika',
    },
    {
      text: 'b. Slovensko',
    },
    {
      text: 'c. Švýcarsko',
    },
    {
      text: 'd. Německo',
    },
  ],
  isSort: true,
  rightAnswer: [1, 0, 3, 2],
  attempts: 1,
  wrongAnswerMessages: [
    {
      text: `
  Zvolené pořadí není správně. <br />
  <br />
  Správné pořadí je:<br />
  b. Slovensko (79 l)<br />
  a. Česká republika (89,2 l)<br />
  d. Německo (122 l)<br />
  c. Švýcarsko (307 l)<br />
  `,
      time: 4,
    },
  ],
  rightAnswerMessage:
    'Trefa! Gratulujeme, uvedené státy jste seřadili správně.',
  finalInfo: {
    text: `Nejnižší průměrnou denní spotřebu pitné vody má <b>Slovensko (79 l)</b>, kousíček 
  za ním je <b>Česká republika (89,2 l)</b>, o něco větší spotřebu má <b>Německo (122 l)</b> 
  a nejvyšší spotřebu pitné vody má v tomto případě <b>Švýcarsko (307 l)</b>, které se 
  řadí téměř na úroveň USA, kde je také spotřeba pitné vody velmi vysoká.
  `,
    time: 10,
  },
}

const Question3 = {
  preText: 'Pojďme dále…',
  text:
    '<b>3. Jak už víte, průměrná denní spotřeba pitné vody v České republice byla v loňském roce 89,2 litrů. Kolik myslíte, že byla průměrná denní spotřeba pitné vody v roce 1989?</b>',
  answers: [
    {
      text: 'a. 68,8 litrů',
    },
    {
      text: 'b. 82,6 litrů',
    },
    {
      text: 'c. 128,9 litrů',
    },
    {
      text: 'd. 188,8 litrů',
      isRight: true,
    },
  ],
  attempts: 2,
  wrongAnswerMessages: [
    'Tohle je špatná odpověď. Zkuste to znovu.',
    'Zase vedle. 😊 Tohle je bohužel také špatná odpověď.<br />Správná odpověď je možnost d.',
  ],
  rightAnswerMessage: 'Výborně! Odpověděli jste správně.',
  finalInfo: `Oproti minulému roku (2018) byla v roce 1989 průměrná denní spotřeba pitné 
  vody vyšší téměř o 100 litrů, tedy <b>188,8 litrů.</b>`,
}

const Question4 = {
  preText: 'Rozjezd je za námi. Pojďme na další otázku…',
  text:
    '<b>4. Kolik % odpadních vod v České republice skončí v čističkách odpadních vod, kde je voda vyčištěna a následně vypuštěna zpět do vodního toku?</b>',
  answers: [
    {
      text: 'a. 73,8 %',
      wrongAnswerMessage:
        'Opravdu tak málo? Česká republika je na tom rozhodně lépe. Zkuste to ještě jednou.',
    },
    {
      text: 'b. 83,9 %',
    },
    {
      text: 'c. 92,8 %',
    },
    {
      text: 'd. 97,5 %',
      isRight: true,
    },
  ],
  attempts: 2,
  wrongAnswerMessages: [
    'Je nám líto, tohle je špatná odpověď. Zkuste si tipnout ještě jednou.',
    'Zase vedle. 😊 Tohle je bohužel také špatná odpověď.<br />Správná odpověď je d.',
  ],
  rightAnswerMessage: 'Výborně! Odpověděli jste správně.',
  finalInfo: `V České republice v čističkách odpadních vod skončí neuvěřitelných <b>97,5 %</b> 
  odpadních vod, které jsou následně vyčištěny a vypuštěny zpět do vodního toku.`,
}

const Question5 = {
  preText: 'Další otázka…',
  text:
    '<b>5. V roce 1989 bylo v České republice 604 čističek odpadních vod. Víte, kolik jich je dnes?</b>',
  answers: [
    {
      text: 'a. 1 284',
    },
    {
      text: 'b. 1 923',
    },
    {
      text: 'c. 2 677',
      isRight: true,
    },
    {
      text: 'd. 3 240',
    },
  ],
  attempts: 2,
  wrongAnswerMessages: [
    'Tohle je špatná odpověď. Zkuste to znovu.',
    'Zase vedle. 😊 Tohle je bohužel také špatná odpověď.<br />Správná odpověď je c.',
  ],
  rightAnswerMessage: 'Výborně! Odpověděli jste správně.',
  finalInfo: `Od roku 1989 v České republice vzniklo několik nových čističek odpadních 
  vod. Přesněji řečeno násobně více. Nyní naši vodu čistí a vrací do vodního toku <b>2 677 čističek.</b>`,
}

const Question6 = {
  preText: 'Jdeme na další otázku…',
  text:
    '<b>6. Tušíte, jaká je průměrná cena za 1 kubík (m<sup>3</sup>) vody v České republice? Tipněte si…</b>',
  answers: [
    {
      text: 'a. 64,60 Kč',
    },
    {
      text: 'b. 82,20 Kč',
      isRight: true,
    },
    {
      text: 'c. 94,00 Kč',
    },
    {
      text: 'd. 112,20 Kč',
    },
  ],
  attempts: 2,
  wrongAnswerMessages: [
    'Tohle je špatná odpověď. Zkuste to znovu.',
    'Zase vedle. 😊 Tohle je bohužel také špatná odpověď.<br />Správná odpověď je b.',
  ],
  rightAnswerMessage: 'Výborně! Odpověděli jste správně.',
  finalInfo: `Průměrná cena za 1 kubík (m<sup>3</sup>) pitné vody je v České republice <b>82,20 Kč.</b>
  Jeden litr (dm<sup>3</sup>) tak vyjde zhruba na 8 haléřů. Uvedená cena zahrnuje vodné 
  a stočné.
  `,
}

const Question7 = {
  preText: 'Pojďme na další zapeklitou otázku…',
  text:
    '<b>7. Vyberte postupně uvedené evropské státy od nejnižší po nejvyšší průměrnou cenu za 1 kubík (m<sup>3</sup>) pitné vody.</b>',
  answers: [
    {
      text: 'a. Česká republika ',
    },
    {
      text: 'b. Německo',
    },
    {
      text: 'c. Slovensko',
    },
    {
      text: 'd. Dánsko',
    },
  ],
  attempts: 1,
  isSort: true,
  rightAnswer: [2, 0, 1, 3],
  wrongAnswerMessages: [
    {
      text: `
    Zvolené pořadí není správně. 😊<br />
  <br />
  Správné pořadí je:<br />
  c. Slovensko (2,40 € = cca 62,50 Kč)<br />
a. Česká republika (82,20 Kč)<br />
b. Německo (6 € = cca 156 Kč)<br />
d. Dánsko (9 € = cca 234 Kč)<br />
`,
      time: 4,
    },
  ],
  rightAnswerMessage:
    'Trefa! Gratulujeme, uvedené státy jste seřadili správně.',
  finalInfo: {
    text: `Nejnižší průměrnou cenu za 1 kubík (m<sup>3</sup>) pitné vody má <b>Slovensko</b> (2,40 €, což 
    je v přepočtu přibližně 62,50 Kč při průměrném kurzu 26 Kč/1 €), následuje 
    <b>Česká republika</b> s cenou 82,20 Kč, <b>Německo</b> s cenou 6 € (v přepočtu přibližně 
    156 Kč při průměrném kurzu 26 Kč/1 €) patří k výrazně dražším zemím a nejvyšší 
    průměrnou cenu za 1 kubík (m<sup>3</sup>) pitné vody zaplatí obyvatelé <b>Dánska</b> (9 €, 
    což v přepočtu činí přibližně 234 Kč při průměrném kurzu 26 Kč/1 €).
  `,
    time: 10,
  },
}

const Question8 = {
  preText: 'Blížíme se k cíli…',
  text:
    '<b>8. Tušíte, kolik litrů vody denně proteče vodovodní sítí v České republice?</b>',
  answers: [
    {
      text: 'a. 1251 milionů litrů vody',
    },
    {
      text: 'b. 980 milionů litrů vody',
    },
    {
      text: 'c. 1671 milionů litrů vody',
      isRight: true,
    },
    {
      text: 'd. 1892 milionů litrů vody',
    },
  ],
  attempts: 2,
  wrongAnswerMessages: [
    'Tohle je špatná odpověď. Zkuste to znovu.',
    'Zase vedle. 😊 Tohle je bohužel také špatná odpověď.<br />Správná odpověď je c.',
  ],
  rightAnswerMessage: 'Výborně! Odpověděli jste správně.',
  finalInfo: `Denně vodovodní sítí v České republice proteče neuvěřitelných <b>1671 milionů
  litrů vody.</b>
  `,
}

const Question9 = {
  preText:
    'Uf, snad jste se moc nezapotili. A pokud ano, máme tady otázku na osvěžení. 😊 Jdeme na ni…',
  text: '<b>9. Víte, kolik litrů vody je potřeba na výrobu 1 litru piva?</b>',
  answers: [
    {
      text: 'a. 1 litr',
    },
    {
      text: 'b. 1,5 litru',
    },
    {
      text: 'c. 2,5 litru',
    },
    {
      text: 'd. 4 litry',
      isRight: true,
    },
  ],
  attempts: 2,
  wrongAnswerMessages: [
    'Tohle je špatná odpověď. Zkuste to znovu.',
    'Zase vedle. 😊 Tohle je bohužel také špatná odpověď.<br />Správná odpověď je d.',
  ],
  rightAnswerMessage: 'Výborně! Odpověděli jste správně.',
  finalInfo: `Na výrobu 1 litru lahodného a osvěžujícího národního nápoje, tedy piva, jsou
  potřeba <b>4 litry</b> vody.<br />
  Jen pro zajímavost: na výrobu vína je potřeba přibližně 4,74 litru vody.
  `,
}

const Question10 = {
  preText: 'Jsme v cílové rovince. Poslední otázka je tady…',
  text:
    '<b>10. Tipněte si, kolik litrů vody je potřeba na výrobu 1 balíku kancelářského papíru?</b>',
  answers: [
    {
      text: 'a. 25 litrů',
    },
    {
      text: 'b. 75 litrů',
    },
    {
      text: 'c. 125 litrů',
    },
    {
      text: 'd. 225 litrů',
      isRight: true,
    },
  ],
  attempts: 2,
  wrongAnswerMessages: [
    'Tohle je špatná odpověď. Zkuste to znovu.',
    'Zase vedle. 😊 Tohle je bohužel také špatná odpověď.<br />Správná odpověď je d.',
  ],
  rightAnswerMessage: 'Výborně! Odpověděli jste správně.',
  finalInfo: `Na výrobu 1 balíku kancelářského papíru, který obsahuje 500 listů o plošné
  hmotnosti 80 g/m<sup>2</sup> je potřeba <b>225 litrů</b> vody. 
  `,
}

const questions = [
  Question1,
  Question2,
  Question3,
  Question4,
  Question5,
  Question6,
  Question7,
  Question8,
  Question9,
  Question10,
]

export default questions
