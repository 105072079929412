import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { sortFaqs, splitFaqsToRows, extractKeystoneData } from '../lib/helpers'
import Col from './Col'
import Row from './Row'
import Gap from './Gap'
import Faq from './Faq'
import Link from './Link'
import Button from './Button'
import Title from './Title'
import Container from './Container'

const FaqSection = ({ data, page }) => {
  const { allFaq } = extractKeystoneData(data)
  const categories = sortFaqs(allFaq)
  if (!categories) return null
  const selectedCategory = categories.find(
    ({ showOnPage }) => showOnPage === page,
  )
  if (!selectedCategory) return null
  const { faqs } = selectedCategory
  if (!faqs || faqs.length === 0) return null
  const limitedFaqs = faqs.slice(0, 4)
  return (
    <Container>
      <Col width="100%" alignItems="center">
        <Title textAlign="center" mobileFontSize="36px">FAQ a zajímavosti o vodě</Title>
        <Gap gap="40px" />
        {splitFaqsToRows(limitedFaqs).map((row, key) => (
          <Row
            width="100%"
            key={key}
            justifyContent="center"
            alignItems="flex-start"
            mobileAlignItems="stretch"
            responsive
            bp="mobile"
          >
            {row.map(({ id, title, content }) => (
              <Fragment key={id}>
                <Faq key={id} title={title} content={content} />
                {row.length === 1 && <Faq.Dummy />}
              </Fragment>
            ))}
          </Row>
        ))}
        <Gap gap="60px" mobileGap="40px" />
        <Row
          width="100%"
          justifyContent="center"
          alignItems="center"
          responsive
          bp="tablet"
        >
          <Title.Small textAlign="left" mobileTextAlign="center" bp="tablet">
            Nenalezli jste odpověď na svojí otázku?
          </Title.Small>
          <Gap gap="20px" />
          <Link to={Link.FAQ}>
            <Button.Simple>UKÁZAT DALŠÍ OTÁZKY</Button.Simple>
          </Link>
        </Row>
      </Col>
    </Container>
  )
}

const WithQuery = props => (
  <StaticQuery
    query={query}
    render={queryResult => <FaqSection {...props} data={queryResult} />}
  />
)

export default WithQuery

const query = graphql`
  query {
      allFaq(sort: {order: ASC, fields: sortOrder}) {
        nodes {
          ...FaqFragment
        }
      }
  }
`
