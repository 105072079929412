import React, { useState, Fragment } from 'react'
import styled, { css } from 'styled-components'
import { up } from '../lib/styles'
import Box from './Box'
import Title from './Title'
import Text from './Text'
import Gap from './Gap'
import Row from './Row'
import Col from './Col'
import Sup from './Sup'
import Select from './Select'
import Container from './Container'
import TapsBg from './TapsBg'

import HouseInSrc from '../data/images/icons/house-in.svg'
import HouseOutSrc from '../data/images/icons/house-out.svg'
import DropsSrc from '../data/images/icons/drops.svg'
import MoneySrc from '../data/images/icons/money.svg'
import EqualSrc from '../data/images/icons/equal.svg'
import PlusSrc from '../data/images/icons/plus.svg'

import { regions, averageAmount, invoicedAndDischargedVAT } from '../lib/config'

const roundNumber = (value, decimals = 0) => {
  const roundCoef = 10 ** decimals
  return Math.round(value * roundCoef) / roundCoef
}

const formatNumber = (value, decimals = 0) => {
  const roundValue = roundNumber(value, decimals)
  return roundValue.toString().replace('.', ',')
}

const disabledOpacity = '0.33'

const Plus = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C9.17157 0 8.5 0.671573 8.5 1.5V8.5H1.5C0.671573 8.5 0 9.17157 0 10C0 10.8284 0.671573 11.5 1.5 11.5H8.5V18.5C8.5 19.3284 9.17157 20 10 20C10.8284 20 11.5 19.3284 11.5 18.5V11.5H18.5C19.3284 11.5 20 10.8284 20 10C20 9.17157 19.3284 8.5 18.5 8.5H11.5V1.5C11.5 0.671573 10.8284 0 10 0Z"
      fill="#0099FC"
    />
  </svg>
)

const Minus = () => (
  <svg
    width="20"
    height="3"
    viewBox="0 0 20 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="3" rx="1.5" fill="#0099FC" />
  </svg>
)
const InputSignWrapper = styled(Col).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}))`
  ${({ theme: { colors }, isDisabled }) => css`
    padding: 20px 30px;
    max-width: 100px;
    cursor: pointer;
    height: 60px;
    transition: 0.2s;

    ${up('mobile')} {
      padding: 20px 40px;
    }

    rect,
    path {
      fill: ${colors.lightBlue};
      opacity: ${isDisabled ? disabledOpacity : 1};
    }

    &:hover {
      ${!isDisabled
        && css`
          background: ${colors.backgroundBlue};
          rect,
          path {
            fill: ${colors.textBlue};
          }
        `}
    }
  `}
`

const CalcBox = styled(Box)`
  max-width: 100%;
  width: 100%;
  height: auto;
  padding: 30px;
  ${up('tablet')} {
    padding: 30px 60px;
  }
`

const Divider = styled.div`
  min-width: 1px;
  min-height: 1px;
  background-color: rgba(0, 55, 128, 0.15);
  align-self: stretch;
  flex-shrink: 0;
  margin: 30px 0;
  ${up('tablet')} {
    margin: 0 10px;
  }
`
const InputDivider = styled.div`
  width: 1px;
  background-color: rgba(0, 55, 128, 0.15);
  align-self: stretch;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
`

const calculatePrices = ({ regionIndex, personsCount, daysCount }) => {
  const region = regions[regionIndex]

  const { invoicedPrice, dischargedPrice, year } = region.data[region.data.length - 1]

  const VAT = invoicedAndDischargedVAT[year]

  const invoicedPriceWithVat = roundNumber(invoicedPrice * (1 + VAT / 100), 2)
  const dischargedPriceWithVat = roundNumber(dischargedPrice * (1 + VAT / 100), 2)

  const sumAmount = roundNumber(averageAmount * daysCount * personsCount, 1)
  const sumInvoicedPrice = roundNumber(sumAmount * invoicedPriceWithVat, 0)
  const sumDischargedPrice = roundNumber(sumAmount * dischargedPriceWithVat, 0)

  return {
    sumAmount,
    sumInvoicedPrice,
    sumDischargedPrice,
    sumPrice: sumInvoicedPrice + sumDischargedPrice,
  }
}

const NumberSelect = ({ onChange, value, ...props }) => {
  const increment = () => onChange(value + 1)
  const decrement = () => !isMinusDisabled && onChange(value - 1)
  const isMinusDisabled = value <= 1
  return (
    <Box padding="0" {...props}>
      <Row
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <InputSignWrapper onClick={decrement} isDisabled={isMinusDisabled}>
          <Minus />
        </InputSignWrapper>
        <InputDivider />
        <Col grow={2} alignItems="center" justifyContent="center">
          <Text.HighLight>{value}</Text.HighLight>
        </Col>
        <InputDivider />
        <InputSignWrapper onClick={increment}>
          <Plus />
        </InputSignWrapper>
      </Row>
    </Box>
  )
}

const SwitchItem = styled(Col).attrs(() => ({
  grow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}))`
  ${({ theme: { colors }, isSelected }) => css`
    cursor: pointer;
    transition: 0.2s;
    color: ${isSelected ? colors.textBlue : '#819ec2'};
    min-height: 60px;
    &:hover {
      background: ${colors.backgroundBlue};
    }
  `}
`

const switchData = [
  { label: 'Den', value: 1 },
  { label: 'Měsíc', value: 30 },
  { label: 'Rok', value: 365 },
]

const Switch = ({ data, value: selectedValue, onChange }) => (
  <Box padding="0">
    <Row
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      {data.map(({ label, value }, key) => (
        <Fragment key={key}>
          {key !== 0 && <InputDivider />}
          <SwitchItem
            isSelected={selectedValue === value}
            onClick={() => onChange(value)}
          >
            <Text.HighLight textColor="inherit">{label}</Text.HighLight>
          </SwitchItem>
        </Fragment>
      ))}
    </Row>
  </Box>
)

const SignIcon = styled.img`
  margin: 30px 10px;
`

const PriceCalc = props => {
  const [personsCount, setPersonsCount] = useState(3)
  const [selectedRegionIndex, setSelectedRegionIndex] = useState(0)
  const [daysCount, setDaysCount] = useState(30)

  const {
    sumAmount,
    sumInvoicedPrice,
    sumDischargedPrice,
    sumPrice,
  } = calculatePrices({
    personsCount,
    regionIndex: selectedRegionIndex,
    daysCount,
  })

  const Amount = () => (
    <Col alignItems="center">
      <img src={DropsSrc} alt="Spotřeba vody" />
      <Gap gap="30px" mobileGap="10px" bp="tablet" />
      <Title.DataAmountSmall textAlign="center">
        {formatNumber(sumAmount, 1)}
        <span>
          {' '}
          m<Sup>3</Sup>
        </span>
      </Title.DataAmountSmall>
      <Text.DataDescription textAlign="center">
        průměrná
        <strong> spotřeba</strong>
      </Text.DataDescription>
    </Col>
  )

  const EquationRowContent = () => (
    <>
      <Col alignItems="center">
        <img src={HouseInSrc} alt="Vodné" />
        <Gap gap="30px" mobileGap="10px" bp="tablet" />
        <Title.DataAmountSmall textAlign="center">
          {formatNumber(sumInvoicedPrice, 0)}
          <span> Kč</span>
        </Title.DataAmountSmall>
        <Text.DataDescription textAlign="center">
          průměrná úhrada za
          <strong> vodné</strong>
        </Text.DataDescription>
      </Col>
      <SignIcon src={PlusSrc} alt="Plus" />
      <Col alignItems="center">
        <img src={HouseOutSrc} alt="Stočné" />
        <Gap gap="30px" mobileGap="10px" bp="tablet" />
        <Title.DataAmountSmall textAlign="center">
          {formatNumber(sumDischargedPrice, 0)}
          <span> Kč</span>
        </Title.DataAmountSmall>
        <Text.DataDescription textAlign="center">
          průměrná úhrada za
          <strong> stočné</strong>
        </Text.DataDescription>
      </Col>
      <SignIcon src={EqualSrc} alt="Rovná se" />
      <Col alignItems="center">
        <img src={MoneySrc} alt="Peníze" />
        <Gap gap="30px" mobileGap="10px" bp="tablet" />
        <Title.DataAmountSmall textAlign="center">
          {formatNumber(sumPrice, 0)}
          <span> Kč</span>
        </Title.DataAmountSmall>
        <Text.DataDescription textAlign="center">
          průměrná úhrada
          <strong> celkem</strong>
        </Text.DataDescription>
      </Col>
    </>
  )

  return (
    <Col width="100%" alignItems="center" position="relative">
      <Container>
        <Col
          width="100%"
          alignItems="stretch"
          mobileAlignItems="center"
          bp="tablet"
        >
          <Row
            justifyContent="space-between"
            alignItems="flex-start"
            responsive
            maxWidth="100%"
            bp="tablet"
          >
            <Col width="100%" alignItems="stretch">
              <Text.FieldLabel textAlign="center">Kraj</Text.FieldLabel>
              <Gap gap="15px" />
              <Select.Regions
                value={selectedRegionIndex}
                onChange={setSelectedRegionIndex}
              />
            </Col>
            <Gap gap="30px" />
            <Col width="100%" alignItems="stretch">
              <Text.FieldLabel textAlign="center">
                Počet členů domácnosti
              </Text.FieldLabel>
              <Gap gap="15px" />
              <NumberSelect onChange={setPersonsCount} value={personsCount} />
            </Col>
            <Gap gap="30px" />
            <Col width="100%" alignItems="stretch">
              <Text.FieldLabel textAlign="center">Období</Text.FieldLabel>
              <Gap gap="15px" />
              <Switch
                data={switchData}
                value={daysCount}
                onChange={setDaysCount}
              />
            </Col>
          </Row>
          <Gap gap="40px" />
          <CalcBox>
            <Row
              width="100%"
              justifyContent="space-between"
              responsive
              bp="tablet"
              showBelow="tablet"
            >
              <Amount />
              <Divider />
              <Row justifyContent="space-between" responsive bp="mobile">
                <EquationRowContent />
              </Row>
            </Row>

            <Row
              width="100%"
              justifyContent="space-between"
              responsive
              bp="tablet"
              hideBelow="tablet"
            >
              <Amount />
              <Divider />
              <EquationRowContent />
            </Row>
          </CalcBox>
        </Col>
      </Container>
      <TapsBg
        transparent
        right="0"
        top="240px"
        topMobile="370px"
        xCount={4}
        yCount={1}
        xCountMobile={2}
        yCountMobile={3}
        zIndex={-9}
      />
    </Col>
  )
}

export default PriceCalc
