import styled, { css } from 'styled-components'

import WaveBottomSrc from '../data/images/wave-bottom.svg'
import WaveBottomMenuSrc from '../data/images/wave-bottom-menu.svg'
import WaveTopSrc from '../data/images/wave-top3.svg'
import WaveTopMenuSrc from '../data/images/wave-top-menu.svg'

const Waves = styled.span`
  ${({ top = false, menu = false }) => css`
    position: absolute;
    width: 100%;
    left: 0;
    font-size: 0;
    border: none;
    ${!top
      && css`
        height: 17px;
        bottom: -3px;
        background: url(${menu ? WaveBottomMenuSrc : WaveBottomSrc});
      `}
    ${top
      && css`
        height: 17px;
        top: -3;
        background: url(${menu ? WaveTopMenuSrc : WaveTopSrc});
      `}
  `}
`

export default Waves
