import NunitoSansLight from '../data/fonts/NunitoSans-Light.ttf'
import NunitoSansRegular from '../data/fonts/NunitoSans-Regular.ttf'
import NunitoSansBold from '../data/fonts/NunitoSans-Bold.ttf'
import VentiCFMedium from '../data/fonts/VentiCF-Medium.woff2'
import VentiCFBold from '../data/fonts/VentiCF-Bold.woff2'

export default {
  NunitoSansLight,
  NunitoSansRegular,
  NunitoSansBold,

  VentiCFMedium,
  VentiCFBold,
}
