import React from 'react'
import styled, { css } from 'styled-components'
import { up, addPagePadding } from '../lib/styles'

const Container = styled.div`
  ${({
    theme: { sizes, pageSidePadding, mobilePageSidePadding },
    withoutPadding,
    isIndex,
    position,
    isMenu,
  }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: ${position};
    max-width: ${isIndex ? sizes.contentWidthIndex : sizes.contentWidth}px;
    ${isMenu && css`
      max-width: ${isIndex ? sizes.contentWidthIndex + 48 : sizes.contentWidth + 48}px;
    `}


    ${!withoutPadding
      && css`
        max-width: ${(isIndex ? sizes.contentWidthIndex : sizes.contentWidth)
          + mobilePageSidePadding * 2}px;
        ${up('mobile')} {
          max-width: ${(isIndex
    ? sizes.contentWidthIndex
    : sizes.contentWidth)
            + pageSidePadding * 2}px;
        }
        ${addPagePadding()}
      `}
  `}
`

export default Container
