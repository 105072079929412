import React, { useState, useEffect, Fragment } from 'react'
import styled, { css, keyframes } from 'styled-components'
import Col from './Col'
import Title from './Title'
import Text from './Text'
import Gap from './Gap'
import Row from './Row'
import { up } from '../lib/styles'

import icon1 from '../data/images/path-icons/1.svg'
import icon2 from '../data/images/path-icons/2.svg'
import icon3 from '../data/images/path-icons/3.svg'
import icon4 from '../data/images/path-icons/4.svg'
import icon5 from '../data/images/path-icons/5.svg'
import icon6 from '../data/images/path-icons/6.svg'
import icon7 from '../data/images/path-icons/7.svg'
import icon8 from '../data/images/path-icons/8.svg'

const data = [
  {
    title: 'Čerpání',
    icon: icon5,
    content:
      'Z přírody se čerpá voda pro další zpracování. A není jí málo – ročně Českou vodovodní sítí proteče 610 milionů kubíků vody. To je téměř 61 000 litrů na jednoho obyvatele České republiky. Uvedené množství ale zahrnuje nejen osobní spotřebu, ale také průmyslově a podnikatelsky využívanou vodu.',
  },
  {
    title: 'Výroba pitné vody',
    icon: icon6,
    content:
      'Příroda je možná geniální, člověk to po ní ale stejně raději zkontroluje. Získaná voda je opět mechanicky a chemicky upravena (zejména s důrazem na odstranění mikroorganismů), aby splňovala veškerá kritéria kvality.',
  },
  {
    title: 'Kontrola a dezinfekce',
    icon: icon7,
    content:
      'Předtím, než se voda dostane do distribuční sítě, prochází kontrolou. Nežijí v ní nežádoucí mikroorganismy? Splňuje veškeré zákonné normy? Voda je zde také chlorována. Právě chlor je příčinou odlišnosti v chuti vody napříč republikou. Pokud máte pocit, že právě vaše voda má chemický „ocásek“, pravděpodobně obsahuje více chlóru.',
  },
  {
    title: 'Vodojem',
    icon: icon8,
    content:
      'Architektonicky výrazné stavby plní množství funkcí. Shromažďují v sobě vodu, díky které mohou vyrovnat nerovnováhu mezi přítoky a odběry. Užitečné jsou i v případě požáru.',
  },
  {
    title: 'Pitná voda tekoucí z kohoutku',
    icon: icon1,
    content:
      'První vodovod na našem území existoval už v roce 1348; kvalitní a nezávadné pitné vody jsme se ale dočkali až těsně před 1. světovou válkou. Dnes je voda běžnou komoditou a pro přístup k ní stačí jen otočit kohoutkem. Cesta vody do našich bytů je ale poměrně dobrodružná. ',
  },
  {
    title: 'Vzhůru do odpadu',
    icon: icon2,
    content:
      'Spláchnutí toalety, vypuštění vany, odtok vody z pračky. Právě tak posíláme vodu na cestu, během které je mechanicky a nakonec i biologicky vyčištěna. Voda putuje podzemními trubkami, které jsou součástí sítě kanalizace.',
  },
  {
    title: 'Odkanalizování a čištění',
    icon: icon3,
    content:
      'Moderní čistírny odpadních vod kombinují mnoho mechanických a biologických postupů – např. sedimentaci, filtraci, chemické srážení a mnoho dalších procesů. Využívají i písek, aktivní uhlí nebo UV záření. Cílem je odstranění mechanických nečistot, ale také organických látek, radioaktivních stop nebo mikroorganismů.',
  },
  {
    title: 'Návrat do přírody',
    icon: icon4,
    content:
      'Vyčištěná voda se zpravidla vypouští do povrchových vod (řek, nádrží nebo rybníků) a někdy také vsakuje do vod podzemních. Zde podstupuje i další kolo čištění, tentokrát programované přírodou.',
  },
]

const NumberCircle = styled.div`
  ${({ theme: { colors, fonts }, isActive }) => css`
    position: absolute;
    width: 50px;
    height: 50px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${colors.lightBlue};
    font-family: ${fonts.venti};
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
    transition: 0.2s;
    cursor: pointer;

    padding-bottom: 5px;

    ${up('mobile')} {
      padding-bottom: 8px;
      font-size: 28px;
      width: 60px;
      height: 60px;
    }

    &:hover {
      color: ${!isActive && colors.darkBlue};
    }

    ${isActive
      && css`
        background-color: ${colors.green};
        color: white;
      `}

    transform: translate(-50%, -50%);
    &:nth-child(1) {
      top: 0;
      left: 50%;
    }
    &:nth-child(2) {
      top: 15%;
      left: 86%;
    }
    &:nth-child(3) {
      top: 50%;
      left: 100%;
    }
    &:nth-child(4) {
      top: 85%;
      left: 86%;
    }
    &:nth-child(5) {
      top: 100%;
      left: 50%;
    }
    &:nth-child(6) {
      top: 85%;
      left: 14%;
    }
    &:nth-child(7) {
      top: 50%;
      left: 0;
    }
    &:nth-child(8) {
      top: 14%;
      left: 15%;
    }
  `}
`

const CircleWrapper = styled.div`
  position: relative;
  height: auto;
  max-width: 100%;
  width: 400px;
  ${up(1100)} {
    width: 490px;
  }
`

const CircleIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  width: 70%;
  max-width: 250px;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
  }
`

const STEPS = 8
const RADIUS = 243
const DASHARRAY = 2 * Math.PI * RADIUS
const getOffset = (activeItem, previousActiveItem, isAutoPlaying) => {
  if (isAutoPlaying) {
    activeItem += 1
    previousActiveItem += 1
  }
  if (activeItem === 0 && previousActiveItem > STEPS / 2) {
    return 0
  }
  return DASHARRAY * (1 - activeItem / STEPS)
}

const Circle = ({
  activeItem,
  previousActiveItem,
  isAutoPlaying,
  ...props
}) => {
  let dashOffset = getOffset(activeItem, previousActiveItem, isAutoPlaying)
  if (isAutoPlaying) dashOffset += 0.1 // workaround for changing transition speed after click

  const transition = isAutoPlaying ? '10s linear' : '1s'
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 490 490"
      style={{ transform: 'rotate(-90deg)' }}
      {...props}
    >
      <circle
        cx="50%"
        cy="50%"
        r={RADIUS}
        fill="none"
        stroke="#bfe5fe"
        strokeWidth="6"
      />
      <circle
        cx="50%"
        cy="50%"
        r={RADIUS}
        fill="none"
        stroke="#0099fc"
        strokeWidth="6"
        strokeLinecap="round"
        style={{
          transition,
        }}
        strokeDasharray={DASHARRAY}
        strokeDashoffset={dashOffset}
      />
    </svg>
  )
}

const CIRCLE_BP = 900

class PathSection extends React.Component {
  interval = null

  constructor(props) {
    super(props)
    this.circleRef = React.createRef()
    this.state = {
      activeItem: 0,
      previousActiveItem: 0,
      isAutoPlaying: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.autoplay()
    }, 1000)
    window.addEventListener('scroll', this.listenToScroll)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const { isAutoPlaying } = this.state
    if (!isAutoPlaying) return
    if (!this.circleRef || !this.circleRef.current) return
    const circleRef = this.circleRef.current
    const { bottom } = circleRef.getBoundingClientRect()
    if (bottom < 0) {
      this.setState({ isAutoPlaying: false })
    }
  }

  autoplay = () => {
    this.setState({ isAutoPlaying: true })
    this.interval = setInterval(() => {
      const { isAutoPlaying } = this.state
      if (!isAutoPlaying) return clearInterval(this.interval)
      return this.setState(({ activeItem }) => ({
        previousActiveItem: activeItem,
        activeItem: activeItem === 1000 * data.length ? 0 : activeItem + 1,
      }))
    }, 10000)
  }

  render() {
    const { activeItem, previousActiveItem, isAutoPlaying } = this.state

    const onActiveItemClick = key => {
      if (isAutoPlaying) this.setState({ isAutoPlaying: false })
      this.setState({ previousActiveItem: activeItem, activeItem: key })
    }

    const activeItemDataIndex = activeItem % data.length

    const { title, content } = data[activeItemDataIndex]
    return (
      <>
        <Row width="100%" alignItems="center" responsive bp={CIRCLE_BP}>
          <Col
            grow={1}
            maxWidth="100%"
            padding="0 32px"
            mobilePadding="32px 20px"
            bp="tablet"
          >
            <CircleWrapper ref={this.circleRef}>
              <Circle
                activeItem={activeItem}
                previousActiveItem={previousActiveItem}
                isAutoPlaying={isAutoPlaying}
              />
              <div>
                {data.map((data, key) => (
                  <NumberCircle
                    key={key}
                    isActive={activeItemDataIndex === key}
                    onClick={() => onActiveItemClick(key)}
                  >
                    {`0${key + 1}`}
                  </NumberCircle>
                ))}
              </div>
              <CircleIconWrapper>
                <img src={data[activeItemDataIndex].icon} alt="" />
              </CircleIconWrapper>
            </CircleWrapper>
          </Col>
          <Gap gap="40px" mobileGap="20px" bp={CIRCLE_BP} />
          <Col.Text mobileAlignItems="flex-start" bp={CIRCLE_BP}>
            <Title.Sub textAlign="left" mobileTextAlign="left" bp={CIRCLE_BP}>
              {`0${activeItemDataIndex + 1}`} <strong>{title}</strong>
            </Title.Sub>
            <Gap.Title />
            <Text mobileTextAlign="left" bp={CIRCLE_BP}>
              {content}
            </Text>
          </Col.Text>
        </Row>
      </>
    )
  }
}

export default PathSection
