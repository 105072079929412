// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-template-js": () => import("./../src/templates/ArticleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-caste-otazky-js": () => import("./../src/pages/caste-otazky.js" /* webpackChunkName: "component---src-pages-caste-otazky-js" */),
  "component---src-pages-cena-vody-js": () => import("./../src/pages/cena-vody.js" /* webpackChunkName: "component---src-pages-cena-vody-js" */),
  "component---src-pages-cenik-js": () => import("./../src/pages/cenik.js" /* webpackChunkName: "component---src-pages-cenik-js" */),
  "component---src-pages-cesta-vody-js": () => import("./../src/pages/cesta-vody.js" /* webpackChunkName: "component---src-pages-cesta-vody-js" */),
  "component---src-pages-clanky-js": () => import("./../src/pages/clanky.js" /* webpackChunkName: "component---src-pages-clanky-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kvalita-vody-js": () => import("./../src/pages/kvalita-vody.js" /* webpackChunkName: "component---src-pages-kvalita-vody-js" */),
  "component---src-pages-o-webu-js": () => import("./../src/pages/o-webu.js" /* webpackChunkName: "component---src-pages-o-webu-js" */)
}

