import React from 'react'
import styled, { css } from 'styled-components'

const Sup = styled.div`
  display: inline;
  vertical-align: super;
  font-size: smaller;
  font-weight: inherit;
`

export default Sup
