import React from 'react'
import styled, { css } from 'styled-components'
import Title from './Title'
import Col from './Col'
import Button from './Button'

const borderRadius = '6px'

const Box = styled.div`
  ${({ theme, isArticle, padding = '30px' }) => css`
    box-shadow: 0 10px 60px -10px rgba(4, 62, 134, 0.2);
    border-radius: ${borderRadius};
    background-color: #ffffff;
    padding: ${padding};
    text-align: left;
    flex-grow: 1;
    max-width: 370px;
    width: 100%;

    ${isArticle
      && css`
        max-width: 270px;
        flex-basis: 270px;
        padding: ${padding || '20px'};
        text-align: center;
        min-height: 320px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
      `}
  `}
`

Box.Article = ({ title, ...props }) => (
  <Box isArticle {...props}>
    <Col height="100%" grow={1}>
      <Col width="100%" grow={1} alignItems="center" justifyContent="center">
        <Title.Small>{title}</Title.Small>
      </Col>
      <Col width="100%" alignItems="center" justifyContent="center">
        <Button.Simple small>PŘEČÍST ČLÁNEK</Button.Simple>
      </Col>
    </Col>
  </Box>
)

Box.Bottom = styled.div`
  background-color: #ecf8ff;
  padding: 30px;
  width: 100%;
  border-bottom-left-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
`

export default Box
