import React from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import Col from './Col'
import Row from './Row'
import Gap from './Gap'
import Link from './Link'
import Container from './Container'
import TapsBg from './TapsBg'
import Waves from './Waves'
import Text from './Text'
import { up, addPagePadding } from '../lib/styles'
import links from '../lib/links'

import logoSrc from '../data/images/logo.svg'
import menuClose from '../data/images/icon-close-menu.svg'
import menuOpen from '../data/images/icons/menu.svg'
import vakzlinLogo from '../data/images/vakzlin-logo.png'

const BackgroundIndexWrapper = styled.div`
  ${() => css`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1020px;
    z-index: -10;
    background: linear-gradient(0, #ffffff 0%, #d9f1ff 100%);
  `}
`

const BackgroundIndex = (props) => (
  <BackgroundIndexWrapper {...props}>
    <TapsBg
      transparent
      right="0"
      top="180px"
      topMobile="125px"
      xCount={3}
      yCount={3}
      xCountMobile={1}
      yCountMobile={4}
    />
  </BackgroundIndexWrapper>
)

const BackgroundBottomWrapper = styled.div`
  ${() => css`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 750px;
    z-index: -10;
    background-image: linear-gradient(180deg, #ffffff 0%, #d9f1ff 100%);
  `}
`

const BackgroundBottom = (props) => (
  <BackgroundBottomWrapper {...props}>
    <TapsBg
      transparent
      left="0"
      bottom="0"
      xCount={1}
      yCount={3}
      xCountMobile={1}
      yCountMobile={4}
    />
  </BackgroundBottomWrapper>
)
const BackgroundWrapper = styled.div`
  ${({ isCustomPage, theme: { colors } }) => css`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 350px;
    z-index: -10;

    background-color: ${colors.backgroundBlue};

    ${up('mobile')} {
      height: 440px;
    }

    ${isCustomPage
      && css`
        height: 100px;
        ${up('mobile')} {
          height: 150px;
        }
      `} /* background: linear-gradient(0, #ffffff 0%, #d9f1ff 100%); */
  `}
`

const Background = ({ isCustomPage, ...props }) => (
  <BackgroundWrapper isCustomPage={isCustomPage} {...props}>
    {!isCustomPage ? <TapsBg wavedBottom /> : <Waves />}
  </BackgroundWrapper>
)
const OuterWrapper = styled.div`
  ${({ isBottom }) => css`
    position: absolute;
    left: 0;
    top: ${!isBottom ? 0 : 'unset'};
    bottom: ${isBottom ? 0 : 'unset'};
    width: 100%;
    display: flex;
    justify-content: center;
    ${up('menu')} {
      background: transparent;
    }
  `}
`

const Wrapper = styled.div`
  ${({ theme: { colors }, isFixed, isBottom }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 700;
    padding: 0;

    transition: 0.3s;
    position: fixed;

    /* background: linear-gradient(
      180deg,
      rgba(217, 241, 255, 1) 50%,
      rgba(217, 241, 255, 0.8) 75%,
      rgba(217, 241, 255, 0)
    ); */

    ${addPagePadding()}
    padding-right: 0;

    ${isFixed
      && css`
        background: ${colors.backgroundBlue};
        box-shadow: 0 10px 60px -10px rgba(4, 62, 134, 0.2);
      `}

    ${isFixed
      && isBottom
      && css`
        bottom: 0;
        background: transparent;
        box-shadow: none;
        position: relative;
        justify-content: center;
      `}

    ${up('mobile')} {
      padding-top: 25px;
      padding-bottom: 25px;
      ${isFixed
        && css`
          padding-top: 0;
          padding-bottom: 0;
        `}
    }

    ${up('menu')} {
      ${addPagePadding()}
      position: relative;
      background: transparent;
      padding-top: 50px;
      padding-bottom: 50px;
      box-shadow: none;
    }
  `}
`

const Logo = styled.img`
  ${() => css`
    width: auto;
    height: 60px;
    padding: 20px 0;
    /* ${up(380)} {
      height: 65px;
    } */
    ${up('mobile')} {
      height: 70px;
    }
    ${up('menu')} {
      height: 65px;
    }
    ${up(1010)} {
      height: 70px;
    }
  `}
`

const BottomLogo = styled.img`
  width: 330px;
`

const Item = styled.div`
  ${({ theme: { fonts, colors }, isActive }) => css`
    position: relative;
    color: ${colors.lightBlue};
    font-family: ${fonts.venti};
    font-weight: 700;
    letter-spacing: 1px;
    transition: 0.2s;
    margin: 0 5px;
    &:hover {
      color: ${colors.darkBlue};
    }

    font-size: 24px;
    line-height: px;
    ${up('menu')} {
      font-size: 18px;
      line-height: 24px;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      height: 0;
      background-color: rgba(0, 55, 128, 0.1);
      transition: 0.3s;
    }

    ${isActive
      && css`
        color: ${colors.darkBlue};
        &::after {
          height: 10px;
        }
      `}
  `}
`

const ItemsWrapper = styled.div`
  ${({ isOpen, theme: { colors } }) => css`
    display: ${isOpen ? 'flex' : 'none'};
    position: absolute;
    flex-direction: column;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 900;
    background: ${colors.backgroundBlue};
    background: linear-gradient(180deg, rgb(217, 241, 255) 70%, rgba(217, 241, 255, 0));
    padding: 100px 20px 200px;

    ${up('menu')} {
      position: relative;
      top: 3px;
      max-width: 709px;
      width: 100%;
      display: flex;
      flex-direction: row;

      justify-content: space-between;
      padding: 0;
      background: transparent;
    }
  `}
`

const MenuIcon = styled.img`
  height: auto;
  /* width: 50px; */
  width: auto;
  cursor: pointer;
  padding: 10px 20px;
  z-index: 1000;
  max-height: 45px;
`

const items = [
  {
    title: 'Cena vody',
    link: links.PRICE,
  },
  {
    title: 'Cesta vody',
    link: links.PATH,
  },
  {
    title: 'Kvalita vody',
    link: links.QUALITY,
  },
  {
    title: 'Časté otázky',
    link: links.FAQ,
  },
  {
    title: 'Články',
    link: links.ARTICLES,
  },
  {
    title: 'O webu',
    link: links.ABOUT,
  },
]

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      fromTop: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
    setTimeout(this.listenToScroll, 1)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    this.setState({
      fromTop: window.pageYOffset,
    })
  }

  isMenuFixed = () => {
    const { fromTop } = this.state
    return fromTop > 0
  }

  render() {
    const { pathname, isBottom, bottomLogo } = this.props
    const { isOpen } = this.state
    let Bg = null
    const isCustomPage = !Object.keys(links).some((key) => links[key] === pathname || `${links[key]}/` === pathname)
      && pathname !== ''
    if (isBottom) {
      Bg = BackgroundBottom
    } else {
      Bg = (pathname === '/' || pathname === '') && !isCustomPage ? BackgroundIndex : Background
    }
    // pokud to je custom link, tak použít jiné menu

    const Content = ({ data }) => (
      <OuterWrapper isBottom={isBottom}>
        <Bg isCustomPage={isCustomPage} />
        <Container withoutPadding isMenu>
          <Wrapper isFixed={this.isMenuFixed()} isBottom={isBottom}>
            <Link
              to={Link.INDEX}
              onClick={() => {
                this.setState({ isOpen: false })
              }}
              style={{ zIndex: 1000 }}
            >
              <Logo src={logoSrc} alt="logo" />
            </Link>

            <Gap gap="40px" mobileGap="20px" bp="tablet" />

            {!isBottom && (
              <Col hideAfter="menu" zIndex={1000}>
                <div
                  onClick={() => {
                    this.setState({ isOpen: !isOpen })
                  }}
                >
                  {isOpen ? (
                    <MenuIcon src={menuClose} alt="close menu" />
                  ) : (
                    <MenuIcon src={menuOpen} alt="open menu" />
                  )}
                </div>
              </Col>
            )}

            <ItemsWrapper isOpen={isOpen} isBottom={isBottom}>
              {items.map(({ title, link }) => (
                <Row key={link} mobilePadding="10px 0">
                  <Link to={link}>
                    <Item
                      isActive={link === pathname || `${link}/` === pathname}
                      onClick={() => {
                        this.setState({ isOpen: false })
                      }}
                    >
                      {title}
                    </Item>
                  </Link>
                </Row>
              ))}
            </ItemsWrapper>
          </Wrapper>

          {isBottom && (
            <Col alignItems="center" justifyContent="center" width="100%">
              <Gap gap="0" mobileGap="20px" bp="menu" />
              <Text textColor="#4a4a48" fontWeight="700">
                Vytvořeno ve spolupráci s
              </Text>
              <Gap gap="10px" />
              <a href="https://vakzlin.cz" target="_blank">
                <Img fixed={data.image.childImageSharp.fixed} />
              </a>
              <Gap gap="60px" />
            </Col>
          )}
        </Container>
      </OuterWrapper>
    )

    return (
      <StaticQuery
        query={graphql`
          query {
            image: file(relativePath: { eq: "images/vakzlin-logo.png" }) {
              childImageSharp {
                fixed(width: 321, quality: 100) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        `}
        render={(data) => <Content data={data} />}
      />
    )
  }
}

export default Menu
