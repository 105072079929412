import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from '.'
import { down, up } from '../lib/styles'

const Button = styled.div`
  ${({
    theme: { fonts, colors },
    fontSize = '16px',
    mobileFontSize,
    fontWeight = 700,
    mobileFontWeight,
    lineHeight = '20px',
    mobileLineHeight,

    padding = '20px 48px 20px',
    mobilePadding = '20px 22px 20px',
    minHeight = '60px',
    width = '406px',
    bp = 'mobile',
  }) => css`
    font-family: ${fonts.venti};
    font-size: ${mobileFontSize || fontSize};
    font-weight: ${mobileFontWeight || fontWeight};
    line-height: ${mobileLineHeight || lineHeight};
    padding: ${mobilePadding || padding};
    color: white;
    background: ${colors.green};
    border-radius: 4px;
    min-height: ${minHeight};
    text-transform: uppercase;
    letter-spacing: 4px;
    box-shadow: 0 25px 40px -25px #20bf55;
    text-align: center;
    cursor: pointer;

    ${up(bp)} {
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      line-height: ${lineHeight};
      padding: ${padding};
    }

    transition: 0.3s;
    &:hover {
      box-shadow: 0 15px 40px -10px #20bf55;
    }
  `}
`
Button.Transparent = styled(Button)`
${({ theme: { colors } }) => css`
  background: transparent;
  border: 2px solid ${colors.lightBlue};
  color: ${colors.lightBlue};
  box-shadow: none;
  &:hover {
      box-shadow: none;
      box-shadow: 0 15px 40px -10px ${colors.lightBlue};

      /* color: white; */
      /* background: ${colors.lightBlue}; */
    }
`}
`


Button.Simple = styled.div`
  ${({ theme: { colors, fonts }, small }) => css`
    color: ${colors.lightBlue};
    font-family: ${fonts.venti};
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 4px;
    line-height: 20px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 12px 17px 10px;
    position: relative;
    text-align: center;
    z-index: 10;
    &::before {
      content: '';
      display: block;
      position: absolute;
      background-color: #d9f1ff;
      height: 50%;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: -1;
      transition: 0.2s;
    }

    ${small
      && css`
        font-size: 14px;
        letter-spacing: 3.5px;
      `}

    &:hover {
      &::before {
        height: 100%;
      }
    }
  `}
`

const ButtonLink = styled(Link)`
  ${({ theme, responsiveFullWidth, bp = 'mobile' }) => css`
    ${responsiveFullWidth
      && css`
        ${down(bp)} {
          width: 100%;
        }
      `}
  `}
`

Button.Link = ({ buttonProps, children, bp, ...rest }) => {
  const Component = Button

  return (
    <ButtonLink noStyle bp={bp} {...rest} style={{ maxWidth: '100%' }}>
      <Component {...buttonProps} bp={bp}>
        {children}
      </Component>
    </ButtonLink>
  )
}

export default Button
