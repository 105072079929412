// has to be filename of ./pages/*
// the actual route paths are defined in translations

const links = {
  INDEX: '/',
  PRICE: '/cena-vody',
  PATH: '/cesta-vody',
  QUALITY: '/kvalita-vody',
  FAQ: '/caste-otazky',
  ARTICLES: '/clanky',
  ABOUT: '/o-webu',
}

export default links
