import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import { up } from '../lib/styles'

const Text = styled.div`
  ${({
    theme: { colors, fonts },
    fontSize = '20px',
    mobileFontSize,
    fontWeight = 300,
    mobileFontWeight,
    lineHeight = '40px',
    mobileLineHeight,
    textAlign = 'left',
    mobileTextAlign,
    maxWidth,
    upperCase,
    letterSpacing,
    noWrap,
    textColor,
    bp = 'mobile',
  }) => css`
    font-family: ${fonts.nunito};
    font-size: ${mobileFontSize || fontSize};
    font-weight: ${mobileFontWeight || fontWeight};
    line-height: ${mobileLineHeight || lineHeight};
    text-align: ${mobileTextAlign || textAlign};
    color: ${colors[textColor] || textColor || colors.textBlue};

    white-space: ${noWrap && 'nowrap'};
    text-transform: ${upperCase && 'uppercase'};
    letter-spacing: ${letterSpacing};
    max-width: ${maxWidth};

    ${up(bp)} {
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      line-height: ${lineHeight};
      text-align: ${textAlign};
    }

    strong {
      font-weight: 700;
    }
  `}
`

Text.DataDescription = props => (
  <Text fontSize="16px" lineHeight="20px" {...props} />
)
Text.DataDescriptionLarge = props => (
  <Text fontSize="20px" lineHeight="30px" {...props} />
)
Text.HighLight = props => (
  <Text fontWeight="700" fontSize="20px" lineHeight="30px" {...props} />
)
Text.FieldLabel = props => (
  <Text fontWeight="400" fontSize="14px" lineHeight="20px" {...props} />
)

export default Text
