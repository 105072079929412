import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Col from './Col'
import Title from './Title'
import Gap from './Gap'
import Text from './Text'
import Link from './Link'
import Select from './Select'
import Row from './Row'
import Table from './Table'
import Sup from './Sup'
import { up } from '../lib/styles'

import { sortedPricesList } from '../lib/priceList'

const citiesSelectData = sortedPricesList.map(row => ({ label: row.city }))

const GreenLinkText = styled(Text).attrs(() => ({
  fontWeight: '700',
  forwardedAs: 'span',
}))`
  color: #20bf55;
  text-decoration: underline;
  text-align: center;
  display: inline;
`

const FilledRow = styled(Row)`
  ${({ theme: { colors } }) => css`
    border-radius: 6px;
    background-color: ${colors.backgroundBlue};
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
    ${up('mobile')} {
      padding: 64px 30px;
    }
    ${up('tablet')} {
      padding: 64px 60px;
    }
  `}
`

const TableHeaderText = props => (
  <Text
    fontSize="14px"
    lineHeight="20px"
    fontWeight="400"
    textColor="textGray"
    {...props}
  />
)
const TableText = props => (
  <Text fontSize="20px" lineHeight="40px" fontWeight="300" {...props} />
)

const TableCol = props => (
  <Col
    responsive
    mobileWidth="100%"
    justifyContent="space-evenly"
    mobileAlignItems="center"
    {...props}
  />
)

const PriceCompareSection = props => {
  const [selectedCityIndex, setSelectedCityIndex] = useState(-1)

  const selectedCity = selectedCityIndex !== -1 && sortedPricesList[selectedCityIndex]

  return (
    <FilledRow width="100%" responsive bp="tablet">
      <Col width="100%" alignItems="center">
        <Title.Small textAlign="left" mobileTextAlign="center" bp="tablet">
          Zajímá vás konkrétní cena vody v místě vašeho bydliště?
        </Title.Small>
        <Gap gap="4px" />
        <Text textAlign="center">
          Aktuálně máme pro vás zmapované{' '}
          <Link to="/cenik" style={{ display: 'inline' }}>
            <GreenLinkText>ceny pro 100 největších měst</GreenLinkText>
          </Link>{' '}
          ČR.
        </Text>
        <Gap gap="33px" />
        <Select
          data={citiesSelectData}
          placeholder="Zvolit město..."
          value={selectedCityIndex}
          onChange={setSelectedCityIndex}
        />
        {selectedCity && (
          <Col maxWidth="585px" width="100%" alignSelf="center">
            <Gap gap="64px" mobileGap="30px" />
            <Row
              width="100%"
              justifyContent="space-between"
              mobileAlignItems="flex-start"
              alignItems="flex-end"
              responsive
            >
              <TableCol>
                <TableHeaderText>Rok</TableHeaderText>
                <Gap gap="8px" />
                <TableText>2021</TableText>
              </TableCol>
              <TableCol>
                <TableHeaderText>
                  Vodné (Kč/m<Sup>3</Sup>)
                </TableHeaderText>
                <Gap gap="8px" />
                <TableText>{selectedCity.invoicedPrice}&nbsp;Kč</TableText>
              </TableCol>
              <TableCol>
                <TableHeaderText>
                  Stočné (Kč/m<Sup>3</Sup>)
                </TableHeaderText>
                <Gap gap="8px" />
                <TableText>{selectedCity.dischargedPrice}&nbsp;Kč</TableText>
              </TableCol>
              <TableCol>
                <TableHeaderText>
                  Celkem (Kč/m<Sup>3</Sup>)
                </TableHeaderText>
                <Gap gap="8px" />
                <TableText fontWeight="700">
                  {selectedCity.sum}&nbsp;Kč
                </TableText>
              </TableCol>
            </Row>

            <Gap gap="32px" />
            {selectedCity.isDoublePrice && (
              <>
                <Text fontSize="16px" lineHeight="24px" maxWidth="790px">
                  Obyvatelé tohoto města platí dvousloužkovou cenu vody, která
                  je složena z vodného, stočného a navíc z paušálního poplatku
                  za vodoměr.{' '}
                  <strong>
                    Cena vody v naší databázi byla přepočtena na jednosložkovou,
                    aby ceny byly porovnatelné.
                  </strong>
                </Text>
                <Gap gap="24px" />
              </>
            )}
            <Text fontSize="16px" lineHeight="24px" maxWidth="790px">
              Provozovatel: {selectedCity.distributor}
            </Text>
          </Col>
        )}
      </Col>
    </FilledRow>
  )
}

export default PriceCompareSection
