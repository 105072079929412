import React from 'react'
import styled, { css } from 'styled-components'
import Row from './Row'
import Col from './Col'
import Text from './Text'
import Gap from './Gap'
import Sup from './Sup'
import Tooltip from './Tooltip'
import { up } from '../lib/styles'

import plusSrc from '../data/images/icons/plus.svg'
import equalSrc from '../data/images/icons/equal.svg'
import dropSrc from '../data/images/drop-price-equation.svg'
import tooltipSrc from '../data/images/icons/tooltip.svg'

const Item = styled(Row).attrs(() => ({
  width: '100%',
}))`
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #ecf8ff;
  min-height: 48px;
  position: relative;
`

const Divider = styled.div`
  height: 2px;
  border-radius: 1px;
  background-color: #819ec2;
  margin: 16px 0 14px;
  width: 100%;
`

const TooltipMark = styled.img.attrs(() => ({
  src: tooltipSrc,
  alt: 'Tooltip',
}))`
  cursor: pointer;
  padding: 14px;
`

const EquationTextComponent = props => (
  <Text.DataDescription
    textColor="textBlue"
    fontWeight="400"
    mobileFontSize="15px"
    bp={340}
    {...props}
  />
)
const EquationText = styled(EquationTextComponent)`
  padding: 12px 0 9px 12px;
`
const EquationResultText = styled(EquationTextComponent).attrs(() => ({
  mobileFontSize: '16px',
}))`
  font-weight: 700;
  text-align: center;
  font-size: 16px;
`

const SignImage = styled.img`
  width: 16px;
  height: 16px;
  margin: 20px 4px;
  ${up(420)} {
    margin: 20px 8px;
  }
`

const DropWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const DropCol = styled(Col)`
  top: 0;
  ${up('mobile')} {
    top: -20px;
  }
`

const PriceEquation = props => (
  <Row alignItems="center" responsive width="100%">
    <Col>
      <Row alignItems="center" responsive>
        <Item maxWidth="195px" mobileMaxWidth="none">
          <EquationText>ekonomicky oprávněné náklady</EquationText>
          <Tooltip
            content="Patří sem například provozní náklady, odpisy majetku, opravy a poplatky za vypouštění odpadních vod."
            noUnderline
            mobileContentStyle={{ top: '20px' }}
          >
            <TooltipMark />
          </Tooltip>
        </Item>
        <SignImage src={plusSrc} alt="plus" />
        <Item maxWidth="129px" mobileMaxWidth="none">
          <EquationText>přiměřený zisk</EquationText>
          <Tooltip
            content="Zisk je investován zpět do sítě (jejího rozvoje či obnovy). Tento krok snižuje riziko nekvalitních služeb."
            noUnderline
            mobileContentStyle={{ top: '20px' }}
          >
            <TooltipMark />
          </Tooltip>
        </Item>
      </Row>
      <Divider />
      <Row width="100%">
        <Item width="100%">
          <EquationText>objem fakturované vody</EquationText>
          {/* <TooltipMark /> */}
        </Item>
      </Row>
    </Col>

    <SignImage src={equalSrc} alt="equal" />

    <DropCol position="relative">
      <img src={dropSrc} alt="" />
      <DropWrapper>
        <Gap gap="50px" />
        <EquationResultText>
          cena vody
          <br />
          (Kč/m<Sup>3</Sup>)
        </EquationResultText>
      </DropWrapper>
    </DropCol>
  </Row>
)

export default PriceEquation
